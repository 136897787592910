import React, { useState } from "react";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import { ProductItem } from "../../components/ProductItem";
import { KwForecast } from "../../components/KwForecast";
import { OrderLayout } from "../../components/OrderLayout";
import {Footer} from "../../components/Footer/Footer";
import "./MushroomDetailDesktop.css";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";

export default function MushroomDetailDesktop(props) {
    const { product, markdownContent, errorMessage, setErrorMessage, refetchProduct, handleBackClick } = props;
    const [showOverlay, setShowOverlay] = useState(false);

    const handleCloseOverlay = () => setShowOverlay(false);
    const handleCloseErrorPopup = () => setErrorMessage(null);

    return (
        <div className="desktop-mushroom-detail">
            <NavigationbarTop />
            <div className="desktop-mushroom-detail_content">
                <aside className="desktop-mushroom-detail-aside">
                    <div className="desktop-mushroom-detail-aside-header">
                        <h1>Produktdetails</h1>
                        <button className="login-desktop_aside-back-button" onClick={handleBackClick}>
                            <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                            <span className="login-desktop_back-link-text">Zurück</span>
                        </button>
                    </div>
                </aside>

                <div className="desktop-mushroom-detail-main">
                    <div className="desktop-mushroom-detail_top-row">
                        <div className="desktop-mushroom-detail_product-order-card">
                            {product ? (
                                <>
                                    <div className="desktop-mushroom-detail_product-order-inner">
                                        <div className="desktop-mushroom-detail_product-section">
                                            <ProductItem
                                                productId={product.productId}
                                                mushroomSpecies={product.mushroomSpecies}
                                                description={product.description}
                                                remainingWeight={product.remainingWeight}
                                                price={product.price}
                                            />
                                        </div>
                                        <div className="desktop-mushroom-detail_order-section">
                                            <OrderLayout
                                                price={product.price}
                                                productId={product.productId}
                                                mushroomSpecies={product.mushroomSpecies}
                                                onFetchError={() => refetchProduct(product.productId)}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <p>Produktdetails werden geladen...</p>
                            )}
                        </div>

                        <div className="desktop-mushroom-detail_forecast-card">
                            <div className="desktop-mushroom-detail_forecast-header">
                                <h2>Erwartete Ernten</h2>
                                <img
                                    className="desktop-mushroom-detail_question-mark-icon"
                                    src="/img/question-mark-icon.png"
                                    alt="Info"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowOverlay(true);
                                    }}
                                />
                            </div>
                            {product && (
                                <KwForecast
                                    productId={product.productId}
                                    mushroomSpecies={product.mushroomSpecies}
                                />
                            )}
                        </div>
                    </div>

                    <div className="desktop-mushroom-detaildescription-card">
                        <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={{__html: markdownContent}}
                        />
                    </div>
                </div>
            </div>

            {showOverlay && (
                <div className="overlay" onClick={handleCloseOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Experimentelles Feature</h3>
                        <p>
                            Die Funktion "Erwartete Ernten" basiert auf KI-gestützten Prognosen,
                            die historische Daten auswerten. Da derzeit nur ein kleiner Datensatz
                            verfügbar ist, können die Vorhersagen ungenau sein. Mit der Zeit und
                            einem größeren Datensatz werden die Vorhersagen immer präziser. Durch
                            einen Klick auf den Stern wird das Datum für dich vorgemerkt. Sobald
                            eine Ernte an diesem Tag erfolgt, wirst du mit einer Nachricht
                            informiert. Du kannst deine markierten Favoriten in den Kontoeinstellungen
                            einsehen und bearbeiten.
                        </p>
                        <button onClick={handleCloseOverlay}>Schließen</button>
                    </div>
                </div>
            )}

            {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
            )}

            <Footer />
        </div>
    );
}
