import React from "react";
import { useNavigate } from "react-router-dom";
import "./RegistrationSuccessPopup.css";

const RegistrationSuccessPopup = ({ message, onClose }) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/login-screen");
        onClose();
    };

    const handleNotificationsHelp = () => {
        navigate("/notifications-help");
        onClose();
    };

    return (
        <div className="register-success-popup-overlay">
            <div className="register-success-popup-content">
                <h2>Erfolgreiche Registrierung</h2>
                <p>{message}</p>
                <p>Du hast Progressive-Web-App noch nie gehört? </p>
                <h3>Dann klicke bitte auf die Anleitung! </h3>
                <div className="register-success-popup-buttons">
                    <button onClick={handleLogin}>Zum Login</button>
                    <button onClick={handleNotificationsHelp}>Anleitung: Webseite als App hinzufügen</button>
                </div>
            </div>
        </div>
    );
};

export default RegistrationSuccessPopup;
