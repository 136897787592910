import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const SwitchIcon = ({
  property1,
  className,
  propertyOff = "/img/switch-off.png",
  propertyOn = "/img/switch-on.png",
}) => {
  return (
    <img
      className={`switch-icon ${property1} ${className}`}
      alt="Property on"
      src={property1 === "off" ? propertyOff : propertyOn}
    />
  );
};

SwitchIcon.propTypes = {
  property1: PropTypes.oneOf(["off", "on"]),
  propertyOff: PropTypes.string,
  propertyOn: PropTypes.string,
};
