import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const NotificationIcon = ({ notifications, to, variant = "default"}) => {
  const [isShaking, setIsShaking] = useState(false);

  const hasNotifications = notifications && notifications.length > 0;

  //use animation if there are notification at stack
  useEffect(() => {
    if (hasNotifications) {
      setIsShaking(true);
      const timer = setTimeout(() => setIsShaking(false), 2000); // stop animation after 1 sec
      return () => clearTimeout(timer);
    }
  }, [hasNotifications]);

    const getImageSrc = () => {
        if (variant === "white") {
            return hasNotifications
                ? "/img/notification-icon-on_white.png"   ///img/notification-icon-on_white_thickLine.png
                : "/img/notification-icon-off_white.png";
        }
        return hasNotifications
            ? "/img/notification-icon2-on.png"
            : "/img/notification-icon2-off.png";
    };

//"/img/notification-icon-8.png" : "/img/property-1-off-6.png"}
  return (
      <Link to={to}>
        <img
            className={`notification-icon ${isShaking ? "shake-animation" : ""}`}
            alt="Notification Icon"
            src={getImageSrc()}
        />
      </Link>
  );
};

NotificationIcon.propTypes = {
  notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        body: PropTypes.string,
        timestamp: PropTypes.string,
      })
  ),
  to: PropTypes.string.isRequired,
};
