import React from 'react';
import "./ConfirmOrderScreenDesktop.css";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {NavigationbarTop} from "../../components/NavigationbarTop";
import {Footer} from "../../components/Footer/Footer";


export default function ConfirmOrderScreenDesktop(props) {
    const {
        cart,
        note,
        setNote,
        totalSum,
        totalWeight,
        paymentMethod,
        setPaymentMethod,
        handleConfirmOrder,
        showThankYouPopup,
        onCloseThankYouPopup,
        showErrorPopup,
        errorPopupMessage,
        handleCloseErrorPopup,
        navigate,
        timeRemaining,
        isExpired,
    } = props;

    return (
        <div className="confirm-order-screen-desktop">
            <NavigationbarTop />

            {showErrorPopup && (
                <ErrorPopup
                    message={errorPopupMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}

            <div className="layout">
                <aside className="my-account-desktop-aside">
                    <div className="my-account-desktop-aside-header">
                        <h1>Bestellung bestätigen</h1>
                        <p>Überprüfe deine Bestellung und wähle eine Zahlungsmehtode aus</p>
                        <button className="login-desktop_aside-back-button" onClick={() => navigate('/shoppingcart-screen')}>
                            <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                            <span className="login-desktop_back-link-text">Zurück</span>
                        </button>
                    </div>
                </aside>

                <div className="confirm-order-screen-desktop_main-content">
                    <div className="confirm-order-container">
                        <div className="left-column">
                            <div className="section products-section">
                                <h3>Deine Produkte</h3>
                                <ul>
                                    {cart.items.map((item) => (
                                        <li key={item.productId}>
                                            {item.mushroomSpecies}: {item.quantity}g
                                            | {(item.price / 1000 * item.quantity).toFixed(2)} €
                                        </li>
                                    ))}
                                </ul>
                                <p className="aggregated-timer">
                                    Reservierung läuft ab in: <strong>{timeRemaining}</strong>
                                </p>
                            </div>

                            <div className="section note-section">
                                <h3>Anmerkung zur Bestellung</h3>
                                <textarea
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>


                        </div>

                        <div className="right-column">
                            <div className="section payment-section">
                                <h3>Zahlungsart wählen</h3>
                                <label>
                                    <input
                                        type="radio"
                                        value="CASH"
                                        checked={paymentMethod === 'CASH'}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    />
                                    Barzahlung
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="BANK_TRANSFER"
                                        checked={paymentMethod === 'BANK_TRANSFER'}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                    />
                                    Überweisung
                                </label>
                                {paymentMethod === 'BANK_TRANSFER' && (
                                    <div className="iban-info-desktop">
                                        <p>IBAN: AT64 3704 0044 0532 0130 00</p>
                                    </div>
                                )}
                            </div>

                            <div className="section summary-section">
                                <h3>Zusammenfassung</h3>
                                <div className="confirm-summary-row">
                                    <span className="confirm-summary-label">Gesamtgewicht:</span>
                                    <span className="confirm-summary-value">{totalWeight} g</span>
                                </div>
                                <div className="confirm-summary-row">
                                    <span className="confirm-summary-label">Gesamtsumme:</span>
                                    <span className="confirm-summary-value">{totalSum} €</span>
                                </div>
                                <div className="confirm-button-container">
                                    <button
                                        className="confirm-button"
                                        onClick={handleConfirmOrder}
                                    >
                                        Bestellung bestätigen
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {showThankYouPopup && (
                <div className="thank-you-popup-overlay">
                    <div className="thank-you-popup">
                        <p>Danke für deine Bestellung!</p>
                            <button
                                className="continue-shopping-button"
                                onClick={onCloseThankYouPopup}
                            >
                                Weiter shoppen
                            </button>
                        </div>
                    </div>
                )}

                <Footer/>
            </div>
            );
            }
