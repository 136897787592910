export const handleFetchError = (error) => {
     console.error("Fehler beim Abrufen der Daten:", error);

    // change typeError to NetworkError
    if (error.name === 'TypeError') {
        error.name = 'NetworkError';
        error.message = 'Netzwerkfehler: Bitte überprüfe deine Internetverbindung. Die angezeigten Daten könnten veraltet sein';
    }

       switch (error.name) {
        case 'NetworkError':
            return { name: error.name, message: error.message };
        case 'SyntaxError':
            return { name: error.name, message: 'Fehler beim Verarbeiten der Serverantwort.' };
        case 'HttpError':
            return { name: error.name, message: error.message };
        case 'Error':
            return { name: error.name, message: error.message };
        case 'UNAUTHORIZED':
            return { name: error.name, message: error.message };
        default:
            return { name: 'UnknownError', message: 'Ein unbekannter Fehler ist aufgetreten.' };
    }

};
