import React from "react";
import "./style.css";

export const MushroomIcon = ({className

}) => {
  return (
      <div className={`mushroom-icon ${className}`}>
        <img
            className="img"
            alt="Mushroom icon"
            src="/img/mushroom%20icon_colored.png"
        />
      </div>
  );
};




