import React from "react";
import { Link } from "react-router-dom";
import { LastOrderScrollView } from "../LastOrderScrollView";
import { getStateDescription, getStatusIconPath } from "../Utils/stateIconMapping";
import { ErrorPopup } from "../Utils/ErrorPopup";
import { NavbarBottomPhone } from "../NavbarBottomPhone";
import "./OrderDetailMobile.css";

export default function OrderDetailMobile({
                                              order,
                                              orderDate,
                                              netPrice,
                                              taxPrice,
                                              formattedTotalPrice,
                                              initiateCancelOrder,
                                              errorMessage,
                                              handleCloseErrorPopup,
                                          }) {
    return (
        <div className="order-detail">
            {/* header */}
            <div className="order-header">
                <Link to="/myorder-screen">
                    <img className="order-detail_back-arrow" alt="Ep back" src="/img/ep-back-56.png"/>
                </Link>
                <h1>Meine Bestellung</h1>
            </div>

            {/* status and date */}
            <div className="order-status">
                <span className="status-text">vom: {orderDate}</span>
                <span className="status-text">Status: {getStateDescription(order.state)}
                    <img
                        src={getStatusIconPath(order.state)}
                        alt="Status Icon"
                        className="status-icon"
                    />
                    </span>
                <span className="status-text">Bestellnummer: {order.orderId}</span>
                {/* cancel button only visible when pending state */}
                {order?.state === 'PENDING' && (
                    <button
                        className="account-screen-mobile_cancel-button"
                        onClick={() => initiateCancelOrder(order?.orderId)}
                    >
                        Bestellung stornieren
                    </button>
                )}
            </div>

            {/* horizontal scrollview */}
            <div className="product-scrollview">
                <LastOrderScrollView products={order.productOrders}/>
            </div>


            {/* invoice */}
            <div className="order-detail_summary">
                {order.productOrders && Array.isArray(order.productOrders) ? (
                    order.productOrders.map((product) => (
                        <div className="order-detail_summary-item" key={product.productId}>
                            <div className="product-name">{product.mushroomSpecies}</div>
                            <div className="product-details">
                                <div className="product-weight"> {product.weightG} g</div>
                                <div className="product-price"> {product.price.toFixed(2)} €</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Keine Produkte in dieser Bestellung.</p>
                )}


                {/* order summary */}
                <div className="order-detail-summary-item-total">
                    <div className="total-summary-row">
                        <div className="total-product-price">
                            <div className="product-weight">{order.totalWeight} g</div>
                            <div className="product-netto">Netto: {netPrice} €</div>
                        </div>

                        <div className="price-breakdown">
                            <div className="product-tax">USt: {taxPrice} €</div>
                            <div className="total-product-price">
                                <span className="total-label">Gesamtsumme:</span>
                                <span className="brutto-price">{formattedTotalPrice} €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup}/>
            )}

            <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
        </div>
    );
}
