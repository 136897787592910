// helper function to put date into european format
export const normalizeDateInput = (input) => {
    // pattern DD.MM.YYYY
    const ddmmyyyyDot = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const matchDot = input.match(ddmmyyyyDot);
    if (matchDot) {
        const [_, dd, mm, yyyy] = matchDot;
        return `${yyyy}-${mm}-${dd}`;
    }

    // pattern DD-MM-YYYY
    const ddmmyyyyDash = /^(\d{2})-(\d{2})-(\d{4})$/;
    const matchDash = input.match(ddmmyyyyDash);
    if (matchDash) {
        const [_, dd, mm, yyyy] = matchDash;
        return `${yyyy}-${mm}-${dd}`;
    }

    // pattern D.M.YYYY (single-digit day or month)
    const dmmyyyyDot = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;
    const matchSingleDot = input.match(dmmyyyyDot);
    if (matchSingleDot) {
        const [_, d, m, yyyy] = matchSingleDot;
        const dd = d.padStart(2, "0");
        const mm = m.padStart(2, "0");
        return `${yyyy}-${mm}-${dd}`;
    }

    // pattern D-M-YYYY (single-digit day or month)
    const dmmyyyyDash = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
    const matchSingleDash = input.match(dmmyyyyDash);
    if (matchSingleDash) {
        const [_, d, m, yyyy] = matchSingleDash;
        const dd = d.padStart(2, "0");
        const mm = m.padStart(2, "0");
        return `${yyyy}-${mm}-${dd}`;
    }

    // null if none fits
    return null;
};



export const matchesDate = (orderDate, searchTerm) => {
    // replace . and / to -
    const normalizedSearchTerm = searchTerm.replace(/\./g, '-').replace(/\//g, '-');

    // split in part
    const parts = normalizedSearchTerm.split('-');

    if (parts.length === 3) {
        // Format: DD-MM-YYYY -> convert to YYYY-MM-DD
        const [d, m, yyyy] = parts;
        const dd = d.padStart(2, "0");
        const mm = m.padStart(2, "0");
        const formattedDate = `${yyyy}-${mm}-${dd}`;
        return orderDate === formattedDate;
    } else if (parts.length === 2) {
        // Format: DD-MM -> search "-MM-DD" in orderDate
        const [d, m] = parts;
        const dd = d.padStart(2, "0");
        const mm = m.padStart(2, "0");
        const pattern = `-${mm}-${dd}`;
        return orderDate.includes(pattern);
    } else if (parts.length === 1) {
        //single parts, day, month, year
        const [part] = parts;
        return orderDate.includes(part);
    }

    // no valid date
    return false;
};
