import React, {useEffect, useState} from "react";

import "./LocationScreenMobile.css";
import LocationScreenMobile from "./LocationScreenMobile";
import {useWindowWidth} from "../../breakpoints";
import LocationScreenDesktop from "./LocationScreenDesktop";
import { useJsApiLoader } from "@react-google-maps/api";

//this need to be static, otherwise loadscript is reloaded unintentionally
const libraries = ["marker"];

export const LocationScreen = () => {
    const screenWidth = useWindowWidth();
    const companyLocation  = { lat: 48.196021, lng: 15.525756 };
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [userLocation, setUserLocation] = useState("");
    const [userCoords, setUserCoords] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    // load and place markers
    useEffect(() => {
        if (isLoaded && mapInstance) {
            // marker for company location
            new window.google.maps.marker.AdvancedMarkerElement({
                position: companyLocation,
                map: mapInstance,
                title: "Darkstone Mycology",
            });

            // marker for user, if there is one
            if (userCoords) {
                new window.google.maps.marker.AdvancedMarkerElement({
                    position: userCoords,
                    map: mapInstance,
                    title: "Ihr Standort",
                });
            }
        }
    }, [isLoaded, mapInstance, userCoords]);

    // determining location with geolocation API
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserCoords({ lat: latitude, lng: longitude });
                    fetchDirections({ lat: latitude, lng: longitude }); // calculate route based on the coordinates
                },
                (error) => {
                    console.error("Fehler beim Abrufen des Standorts:", error);
                    alert("Standort konnte nicht abgerufen werden. Bitte überprüfen Sie Ihre Standortberechtigungen.");
                }
            );
        } else {
            alert("Ihr Gerät unterstützt keine Standortbestimmung.");
        }
    };

    // calculate route
    const fetchDirections = (origin) => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                destination: companyLocation,
                origin: origin || userLocation, // coordinates of user or address input
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirectionsResponse(result);
                } else {
                    console.error("Error fetching directions", result);
                    alert("Route konnte nicht berechnet werden. Bitte überprüfen Sie die Eingabe.");
                }
            }
        );
    };

    if (loadError) {
        return <div>Fehler beim Laden der Google Maps API</div>;
    }

    if (!isLoaded) {
        return <div>Lade Google Maps...</div>;
    }

    return (
        <>
            {screenWidth < 1024 ? (
                <LocationScreenMobile
                    companyLocation={companyLocation}
                    directionsResponse={directionsResponse}
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                    userCoords={userCoords}
                    setUserCoords={setUserCoords}
                    setMapInstance={setMapInstance}
                    getUserLocation={getUserLocation}
                    fetchDirections={fetchDirections}
                />
            ) : (
                <LocationScreenDesktop
                    companyLocation={companyLocation}
                    directionsResponse={directionsResponse}
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                    userCoords={userCoords}
                    setUserCoords={setUserCoords}
                    setMapInstance={setMapInstance}
                    getUserLocation={getUserLocation}
                    fetchDirections={fetchDirections}
                />
            )}
        </>
    );
};
