import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ShoppingCartIcon = ({
                                    className,
                                    isActive=false,
                                    variant = "default",
                                    shoppingCartIcon = "/img/shopping-cart-icon-39.png",
                                    activeShoppingCartIcon = "/img/shopping-cart-icon-active.png",
                                    whiteShoppingCartIcon = "/img/shopping-cart-icon-white.png",
                                 }) => {

    const getImageSrc = () => {
        if (variant === "white") {
            return isActive ? activeShoppingCartIcon : whiteShoppingCartIcon;
        }
        return isActive ? activeShoppingCartIcon : shoppingCartIcon;
    };


  return (
    <img
      className={`shopping-cart-icon ${className}`}
      alt="Shopping cart icon"
      src={getImageSrc()}
    />
  );
};

ShoppingCartIcon.propTypes = {
  shoppingCartIcon: PropTypes.string,
  isActive: PropTypes.bool,
};
