import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./ShoppingcartScreenMobile.css";
import {useCart} from "../../context/CartContext";
import {
  actualizeRemainingWeightForProduct,
  createOrder,
  fetchSuggestedWeights,
  releaseHarvests,
  reserveHarvest
} from "../../Utils/DatabaseFetch";
import {useAuth} from "../../context/AuthContext";
import ShoppingcartScreenDesktop from "./ShoppingcartScreenDesktop";
import ShoppingcartScreenMobile from "./ShoppingcartScreenMobile";
import {useWindowWidth} from "../../breakpoints";
import {ErrorPopupConfigurableButtons} from "../../components/Utils/ErrorPopupConfigurableButtons";

export const ShoppingcartScreen = () => {
    const screenWidth = useWindowWidth();
    const location = useLocation();
    const { productId } = location.state || {};
    const { cart, removeFromCart, addToCart, clearCart, updateCartItemExpiry } = useCart();
    const [showSuggestedWeightsPopup, setShowSuggestedWeightsPopup] = useState(false);
    const [showExpiredPopup, setShowExpiredPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showLoginErrorPopup, setShowLoginErrorPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [expiredItems, setExpiredItems] = useState([]);
    const [suggestedWeights, setSuggestedWeights] = useState(null); //
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    const [note, setNote] = useState(''); // for note field
    const navigate = useNavigate();
    const [errorPopupMessage, setErrorPopupMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();


  // calculate total sum
  const totalSum = Array.isArray(cart.items)
      ? cart.items
          .reduce((acc, item) => {
            const price = Number(item.price);
            const quantity = Number(item.quantity);
            return acc + price * (quantity / 1000);
          }, 0)
          .toFixed(2)
      : "0.00";

  //total weight
  const totalWeight = Array.isArray(cart.items)
      ? cart.items.reduce((acc, item) => acc + item.quantity, 0)
      : 0;



  useEffect(() => {
    const expired = cart.items.filter(item => item.isExpired);

    //set popup ad expired state
    if (expired.length > 0 && !showErrorPopup) {
      setExpiredItems(expired);
      setShowExpiredPopup(true);

    } else {
      setExpiredItems([]);
      setShowExpiredPopup(false);

    }
  }, [cart.items]);

  //if fetched data is an exact match
  const reserveExactMatch = async (item, exactMatch) => {
    removeFromCart(item.productId); // remove expired product first

    const reserveResults = await reserveHarvest(exactMatch.harvestIds);

    // add new reservation to cart
    addToCart({
      ...item,
      quantity: Number(exactMatch.totalWeight),
      price: Number(item.price),
      harvestIds: reserveResults.map((res) => res.harvestId),
      expiryDates: reserveResults.map((res) => res.expiryDate),
      isExpired: false,
    });
  };


  //for the suggestWeights from the backend
  const handleSuggestedWeightsSelection = async (weightData) => {
    removeFromCart(selectedItem.productId); // remove item first
    setIsLoading(true);
    try {
    const reserveResults = await reserveHarvest(weightData.harvestIds);
    setIsLoading(false);
    // add new reservation to cart
    addToCart({
      ...selectedItem,
      quantity: Number(weightData.totalWeight),
      price: Number(weightData.price),
      harvestIds: reserveResults.map((res) => res.harvestId),
      expiryDates: reserveResults.map((res) => res.expiryDate),
      isExpired: false,
    });

    setShowSuggestedWeightsPopup(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExpiredOption = async (item) => {
    setIsLoading(true);
    try {

      // get suggested fetch
      const data = await fetchSuggestedWeights(item.productId, item.quantity);
      setIsLoading(false);
      // temporary array
      const updatedExpiredItems = expiredItems.filter((expiredItem) => expiredItem.productId !== item.productId);

      // set state to remove item immediately
      setExpiredItems(updatedExpiredItems);

      if (data) {
        if (data.exactMatch) {
          // reservation and add it directly to shopping cart
          await reserveExactMatch(item, data.exactMatch);
        } else {
          // if no exact match, open suggestion
          removeFromCart(item.productId);
          setSelectedItem({ ...item, suggestedWeights: data });
          setSuggestedWeights(data);
          setShowSuggestedWeightsPopup(true);
        }
        // if no more entries close the popup
        if (updatedExpiredItems.length === 0) {
          setShowExpiredPopup(false);
        }

      }



      } catch (error) {
        console.error("Fehler beim handle Expire Ooptions:", error);
        setIsLoading(false);
        setShowExpiredPopup(false);
        setShowErrorPopup(true);
        setErrorPopupMessage(error.message || "Ein Fehler ist beim Verarbeiten der Anfrage aufgetreten.");

      }};


  const handleRemoveExpiredItem = async (item) => {
    removeFromCart(item.productId);
    await actualizeRemainingWeightForProduct(productId);
    setExpiredItems((prev) => {
      const updatedItems = prev.filter((expiredItem) => expiredItem.productId !== item.productId);

      // if no more entries close the popup
      if (updatedItems.length === 0) {
        setShowExpiredPopup(false);
      }

      return updatedItems;
    });
  };

  const reReserveAllCartItems = async () => {
    //get all harvestIds for each cart item
    const allHarvestIds = cart.items.flatMap((item) => item.harvestIds);

    if (allHarvestIds.length === 0) {
      throw new Error("Keine harvestIds im Warenkorb gefunden.");
    }

    // one backend request for all items
    const reserveResults = await reserveHarvest(allHarvestIds);

    //create map with harvestID -> expiryDate
    const harvestMap = new Map(
        reserveResults.map((res) => [res.harvestId, res.expiryDate])
    );

    cart.items.forEach((item) => {
      const newExpiryDates = item.harvestIds.map((hId) => {
        const foundDate = harvestMap.get(hId);
        if (!foundDate) {
          throw new Error(`HarvestId ${hId} konnte nicht reserviert werden.`);
        }
        return foundDate;
      });
      // update cart items with new expireDate
      updateCartItemExpiry(item.productId, newExpiryDates);
    });
  };

  const handleNavigateToConfirmOrder = async () => {
    if (user === null) {
     /* setErrorPopupMessage(
          "Bitte zuerst einloggen, bevor du eine Bestellung aufgibst."
      );
      //setShowErrorPopup(true);

      */
      setShowLoginErrorPopup(true);
      return;
    }

    if (cart.items.length === 0) {
      setErrorPopupMessage(
          "Bitte lege zuerst Produkte in den Warenkorb, bevor du eine Bestellung aufgibst."
      );
      setShowErrorPopup(true);
      return;
    }

    // check for expired items
    const expiredItems = cart.items.filter((item) => item.isExpired);
    if (expiredItems.length > 0) {
      setErrorPopupMessage(
          "Einige Produkte in deinem Warenkorb sind abgelaufen. Bitte entferne sie, bevor du fortfährst."
      );
      setShowErrorPopup(true);
      return;
    }

    setIsLoading(true);

    try {
      // set new timer
      await reReserveAllCartItems();

      setIsLoading(false);
      // navigate
      navigate("/confirm-order", {
        state: {cart, note, totalSum, totalWeight}
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Fehler beim erneuten Reservieren:", error);
      setErrorPopupMessage(error.message || "Reservierung fehlgeschlagen.");
      setShowErrorPopup(true);
    }
  };


  const handleRemoveAllExpiredItems = async () => {
    for (const item of expiredItems) {
      removeFromCart(item.productId);
      await actualizeRemainingWeightForProduct(item.productId);
    }

    setExpiredItems([]);
    setShowExpiredPopup(false);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorPopupMessage("");
    if (expiredItems.length > 0) {
      //setShowExpiredPopup(true); // show expired popup again
    }
  };

  if (isLoading) {
    return <div className="shoppingcart-screen">Lädt...</div>;
  }

  if(showLoginErrorPopup) {
    return (
        <ErrorPopupConfigurableButtons
            message="Bitte melde dich zuerst an, bevor du eine Bestellung aufgibst."
            onClose={() => {
              setShowLoginErrorPopup(false);
            }
            }
            buttons={[
              {
                label: "Zur Anmeldung",
                action: () => {
                  setShowLoginErrorPopup(false);
                  navigate("/login-screen");
                },
              },
              {
                label: "Abbrechen",
                action: () => {
                  setShowLoginErrorPopup(false);
                },

              },
            ]}
        />
    );
  }

  return (
      <>
        {screenWidth < 1024 ? (
            <ShoppingcartScreenMobile
                cart={cart}
                removeFromCart={removeFromCart}
                addToCart={addToCart}
                clearCart={clearCart}
                totalSum={totalSum}
                totalWeight={totalWeight}
                note={note}
                setNote={setNote}
                handlePlaceOrder={handleNavigateToConfirmOrder}
                navigate={navigate}
                showErrorPopup={showErrorPopup}
                errorPopupMessage={errorPopupMessage}
                handleCloseErrorPopup={handleCloseErrorPopup}
                showExpiredPopup={showExpiredPopup}
                expiredItems={expiredItems}
                setShowExpiredPopup={setShowExpiredPopup}
                handleExpiredOption={handleExpiredOption}
                handleRemoveExpiredItem={handleRemoveExpiredItem}
                handleRemoveAllExpiredItems={handleRemoveAllExpiredItems}
                showSuggestedWeightsPopup={showSuggestedWeightsPopup}
                selectedItem={selectedItem}
                handleSuggestedWeightsSelection={handleSuggestedWeightsSelection}
                setShowSuggestedWeightsPopup={setShowSuggestedWeightsPopup}
                showThankYouPopup={showThankYouPopup}
                setShowThankYouPopup={setShowThankYouPopup}

            />
        ) : (
            <ShoppingcartScreenDesktop
                cart={cart}
                removeFromCart={removeFromCart}
                addToCart={addToCart}
                clearCart={clearCart}
                totalSum={totalSum}
                totalWeight={totalWeight}
                note={note}
                setNote={setNote}
                handlePlaceOrder={handleNavigateToConfirmOrder}
                navigate={navigate}
                showErrorPopup={showErrorPopup}
                errorPopupMessage={errorPopupMessage}
                handleCloseErrorPopup={handleCloseErrorPopup}
                showExpiredPopup={showExpiredPopup}
                expiredItems={expiredItems}
                setShowExpiredPopup={setShowExpiredPopup}
                handleExpiredOption={handleExpiredOption}
                handleRemoveExpiredItem={handleRemoveExpiredItem}
                handleRemoveAllExpiredItems={handleRemoveAllExpiredItems}
                showSuggestedWeightsPopup={showSuggestedWeightsPopup}
                selectedItem={selectedItem}
                handleSuggestedWeightsSelection={handleSuggestedWeightsSelection}
                setShowSuggestedWeightsPopup={setShowSuggestedWeightsPopup}
                showThankYouPopup={showThankYouPopup}
                setShowThankYouPopup={setShowThankYouPopup}


            />
        )}
      </>
  );
};
