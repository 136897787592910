import React from "react";
import "./style.css";

export const ShiitakeIcon = ({ className }) => {
  return (
    <div className={`shiitake-icon ${className}`}>
      <img
        className="img"
        alt="Shiitake icon"
        src="/img/shiitake-icon-1-3.png"
      />
    </div>
  );
};
