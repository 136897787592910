import React from 'react';
import { NavigationbarTop } from "../../components/NavigationbarTop";
import { ProductScrollView } from "../../Utils/ProductScrollView";
import "./StartScreenDesktop.css";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {Footer} from "../../components/Footer/Footer";

export default function StartScreenDesktop(props) {
    const {
        selectedMushroomType,
        setSelectedMushroomType,
        searchText,
        setSearchText,
        availabilityFilter,
        setAvailabilityFilter,
        loading,
        showLoadingMessage,
        filteredProducts,
        showErrorPopup,
        errorMessage,
        handleCloseErrorPopup,
    } = props;

    return (
        <>
            <NavigationbarTop
            />

            <div className="desktop-content">
                <div className="hero-section">
                    <img
                        className="company-logo"
                        alt="Company Logo"
                        //src="/img/leonardo-phoenix-change-the-lettering-to-darkstone-mycology-as-0-7.png" // Pfad zum Logo
                        src="/img/Logo-9.png"
                    />
                    <img
                        className="hero-image"
                        alt="Hero"
                        //src="/img/leonardo-phoenix-render-an-image-of-a-sleek-modern-smartphone-2-3.png"
                        src="/img/smartphone_5.1.png"
                    />
                </div>

                <div className="filter-section">
                    <div className="title">
                        <h2>Pilzauswahl</h2>
                    </div>

                    <div className="desktop_search-and-filter">
                        <div className="desktop_searchbar">
                            <input
                                type="text"
                                className="desktop_search-input"
                                placeholder="Suche..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>

                        <div className="desktop_filter-dropdown">
                            <select
                                value={availabilityFilter}
                                onChange={(e) => setAvailabilityFilter(e.target.value)}
                            >
                                <option value="Alle">Alle</option>
                                <option value="Verfügbar">Verfügbar</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="product-list">
                    {loading && showLoadingMessage ? (
                        <p>Produkte werden geladen...</p>
                    ) : !loading && filteredProducts.length > 0 ? (
                        <ProductScrollView products={filteredProducts} />
                    ) : (
                        !loading && <p>Keine Produkte verfügbar</p>
                    )}
                </div>

                {showErrorPopup && (
                    <ErrorPopup
                        message={errorMessage}
                        onClose={handleCloseErrorPopup}
                    />
                )}
            </div>

            <Footer />
        </>
    );
}
