import React from "react";
import "./style.css";
import PropTypes from "prop-types";

export const Kastanienseitling = ({ size }) => {
    return (
        <img
            className={`mushroom-image ${size}`}
            alt="Kastanienseitling"
            src="/img/kastanienseitling-1-3.png"
        />
    );
};

Kastanienseitling.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
};
