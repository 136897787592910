import PropTypes from "prop-types";
import React, { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import "./style.css";
import {fetchSuggestedWeights, releaseHarvests, reserveHarvest, actualizeRemainingWeightForProduct} from "../../Utils/DatabaseFetch";
import {PopupAvailable} from "../../screens/PopupAvailable";
import {useCart} from "../../context/CartContext";
import {ErrorPopup} from "../Utils/ErrorPopup";
import {useAuth} from "../../context/AuthContext";
import {ErrorPopupConfigurableButtons} from "../Utils/ErrorPopupConfigurableButtons";


export const OrderLayout = ({
  className,
  price,
  productId, mushroomSpecies, onFetchError,

                            }) => {
  const [quantity, setQuantity] = useState(null);    //state for order amount
  const [suggestedWeights, setSuggestedWeights] = useState(null); //
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showLoginErrorPopup, setShowLoginErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth();

  //event handler for order input
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };



  const handleAddToCartClick = async () => {

      if (!user) {
          /*
          setErrorMessage("Bitte melde dich zuerst an, um Produkte zu bestellen.");
          setShowErrorPopup(true);
          return;
           */

         setShowLoginErrorPopup(true);
         return;
      }


      if (!quantity || quantity <= 0) {

          setErrorMessage("Bitte geben Sie eine gültige Menge ein."); // Set error message for invalid quantity
          return;
      }

        try {
          const data = await fetchSuggestedWeights(productId, quantity);


            if (data.error) {
                setErrorMessage(data.error);
                return;
            }

          if (data) {
              if (!data.belowTarget && !data.aboveTarget && !data.exactMatch) {
                  setErrorMessage("Entschuldigung, es ist keine verfügbare Menge mehr vorhanden."); // No stock available
                  return;
              }

           setSuggestedWeights(data);
           if(data.exactMatch){
             // reserve for every harvestID and get expiryDate
               const reserveResults = await reserveHarvest(data.exactMatch.harvestIds);


               addToCart({
               productId,
               quantity,
               price,
               mushroomSpecies,
               harvestIds: reserveResults.map((res) => res.harvestId),
               expiryDates: reserveResults.map((res) => res.expiryDate),
             });
             //await actualizeRemainingWeightForProduct(productId);
             navigate("/shoppingcart-screen", {state: {productId: productId, quantity: quantity, suggestedWeights}});

           }
           else
           setShowPopup(true);
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Vorschläge:", error);
          setErrorMessage(error.message || "Ein Fehler ist beim Abrufen der Daten aufgetreten."); // Set error message for fetching issues
            if (error.message === "Keine verfügbaren Mengen gefunden") {
                onFetchError(); // invoke callback
            }
        }
      };

  //helper function, for releasing harvest
    function combineHarvestIds(suggestedWeights) {

        const aboveTargetIds = suggestedWeights.aboveTarget?.harvestIds || [];
        const belowTargetIds = suggestedWeights.belowTarget?.harvestIds || [];

        const combinedIds = [...new Set([ ...aboveTargetIds, ...belowTargetIds])];

        return combinedIds;
    }

  const handleClosePopup = () => {
      releaseHarvests(combineHarvestIds(suggestedWeights));
      setShowPopup(false);
  }
    const handleCloseErrorPopup = () => setErrorMessage(null);


    if(showLoginErrorPopup) {
        return (
            <ErrorPopupConfigurableButtons
                message="Bitte melde dich zuerst an, um den Produkte in den Warenkorb zu legen."
                onClose={() => {
                    setShowLoginErrorPopup(false);
                }
                }
                buttons={[
                    {
                        label: "Zur Anmeldung",
                        action: () => {
                            setShowLoginErrorPopup(false);
                            navigate("/login-screen");
                        },
                    },
                    {
                        label: "Abbrechen",
                        action: () => {
                            setShowLoginErrorPopup(false);
                        },

                    },
                ]}
            />
        );
    }

  return (
    <div className={`order-layout ${className}`}>
      <div className="price-section">
        <div className="price-container">
          <p className="price-text">
            Preis / kg : <span className="price-value">{price} €</span>
          </p>
        </div>
      </div>

      <div className="quantity-section">
        <div className="quantity-label-container">
          <div className="quantity-label">
            <div className="price-text">gewünschte Menge (g): </div>
          </div>

          <div className="quantity-input-container">
            <input
                type="number"
                className="quantity-input"
                value={quantity || ""}
                onChange={handleQuantityChange}
                placeholder="Menge in g"
            />


          </div>
        </div>
      </div>

      <button className="add-to-cart-button" onClick={handleAddToCartClick}>
        <div className="add-to-cart-container">
          <div className="add-to-cart-text">In den Warenkorb legen</div>
        </div>
      </button>

        {showPopup && suggestedWeights && productId && mushroomSpecies && price !== undefined && (
          <PopupAvailable

              suggestedWeights={suggestedWeights}
              productId={productId}
              mushroomSpecies={mushroomSpecies}
              price={price}
              onClose={handleClosePopup}
          />
      )}
        {errorMessage && (
            <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
        )}

        {showErrorPopup && (
            <div className="account-screen">
                <ErrorPopup
                    message={errorMessage}
                    onClose={() => {
                        setShowErrorPopup(false); //
                        setErrorMessage(null);
                        navigate("/login-screen");
                    }}
                    buttons={[
                        {
                            label: "Zur Anmeldung",
                            action: () => navigate("/login-screen"),
                        },
                    ]}
                />
            </div>
                )}
            </div>
        );
        }

        OrderLayout.propTypes = {
        price: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,

};
