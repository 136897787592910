import React from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import "./LocationScreenDesktop.css";
import {Footer} from "../../components/Footer/Footer";


export default function LocationScreenDesktop(props) {
    const {
        companyLocation,
        directionsResponse,
        userLocation,
        setUserLocation,
        userCoords,
        setUserCoords,
        setMapInstance,
        getUserLocation,
        fetchDirections,
    } = props;

    return (
        <>
         <NavigationbarTop />

            <div className="location-screen-desktop">

                <div className="location-screen-desktop_content">
                    <div className="location-screen-desktop_info-section">
                        <h2 className="location-screen-desktop_company-title">Darkstone Mycology</h2>
                        <p className="location-screen-desktop_company-address">Uttendorf 13, 3385 Prinzersdorf, Österreich</p>

                        <div className="location-screen-desktop_route-input-container">
                            <button onClick={getUserLocation} className="location-screen-desktop_route-button">
                                Meinen Standort verwenden
                            </button>
                        </div>

                        <div className="location-screen-desktop_route-input-container">
                            <input
                                type="text"
                                placeholder="Geben Sie Ihre Adresse ein"
                                value={userLocation}
                                onChange={(e) => setUserLocation(e.target.value)}
                                className="location-screen-desktop_route-input"
                            />
                            <button onClick={() => fetchDirections()} className="location-screen-desktop_route-button">
                                Route berechnen
                            </button>
                        </div>
                    </div>
                    <div className="location-screen-desktop_map-section">
                        <GoogleMap
                            mapContainerClassName="location-screen-desktop_map"
                            zoom={13}
                            center={companyLocation}
                            options={{
                                mapId: "66ec27780ea5a6fd",
                            }}
                            onLoad={(map) => setMapInstance(map)}
                        >
                            {/* show route */}
                            {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                        </GoogleMap>
                    </div>
                </div>
                <Footer/>
            </div>

        </>

    );
}
