import React from "react";
import { MyOrderItem } from "../../components/MyOrderItem";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { NavigationbarTop } from "../../components/NavigationbarTop";
import "./MyOrderScreenDesktop.css";
import {Footer} from "../../components/Footer/Footer";

export default function MyOrderScreenDesktop({
                                                 loading,
                                                 errorMessage,
                                                 filteredOrders,
                                                 handleFilterChange,
                                                 handleStateFilterChange,
                                                 handleSearchChange,
                                                 handleCloseErrorPopup,
                                                 handleBackClick,
                                                 searchTerm,
                                                 filterOption,
                                                 stateFilterOption,
                                                 yearOptions,
                                             }) {
    return (
        <div className="my-order-screen-desktop">
            <NavigationbarTop />
            <div className="my-order-screen-desktop_content">
                <aside className="my-order-screen-desktop_sidebar">
                    <h2>Filter</h2>
                    <input
                        className="my-order-screen-desktop_search-bar"
                        type="text"
                        placeholder="Suchen..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <select
                        className="my-order-screen-desktop_filter-dropdown"
                        value={stateFilterOption}
                        onChange={handleStateFilterChange}
                    >
                        <option value="ALL">Alle Status</option>
                        <option value="PENDING">Offen</option>
                        <option value="IN_PROGRESS">In Bearbeitung</option>
                        <option value="READY">Abholbereit</option>
                        <option value="COMPLETED">Abgeschlossen</option>
                        <option value="CANCELLED">Storniert</option>
                    </select>
                    <select
                        className="my-order-screen-desktop_filter-dropdown"
                        value={filterOption}
                        onChange={handleFilterChange}
                    >
                        <option value="30Days">Letzte 30 Tage</option>
                        <option value="3Months">Letzte 3 Monate</option>
                        {yearOptions.map((year) => (
                            <option key={year} value={year}>
                                Jahr {year}
                            </option>
                        ))}
                    </select>
                    <button className="my-order-screen-desktop_aside-back-button" onClick={handleBackClick}>
                        <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                        <span className="login-desktop_back-link-text">Zurück zum Konto</span>
                    </button>
                </aside>
                <main className="my-order-screen-desktop_order-list-container">
                    <h1>Meine Bestellungen</h1>
                    {loading ? (
                        <div className="loading">Lädt Bestellungen...</div>
                    ) : errorMessage ? (
                        <div className="error-message">{errorMessage}</div>
                    ) : filteredOrders.length > 0 ? (
                        <div className="my-order-screen-desktop_order-list">
                        {filteredOrders.map((order) => (
                                <MyOrderItem
                                    key={order.orderId}
                                    className="my-order-screen-desktop_order-item"
                                    order={order}
                                    to={`/myorder-detail/${order.orderId}`}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="no-orders">Keine Bestellungen für den angegebenen Zeitraum gefunden.</div>
                    )}
                </main>
            </div>
            {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
            )}
            <Footer />
        </div>
    );
}
