import React, { useState } from "react";
import "./ValidationInput.css";

const ValidationInput = ({
                             value,
                             onChange,
                             placeholder = "Eingabe...",
                             validate,
                             validationMessage = "Ungültige Eingabe",
                             className = "",
                             styleType = "default",
                             label,
                             renderContainer = true,

                         }) => {
    const [error, setError] = useState(null);
    const [touched, setTouched] = useState(false);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        onChange(inputValue);

        if (!touched) {
            setTouched(true); // user haven´t touched the filed yet
        }

        // is valid?
        if (validate) {
            const isValid = validate(inputValue);
            setError(isValid ? null : validationMessage);
        }
    };

    return (
        <div className={`validation-input-container ${className}`}>
            <input
                type="text"
                className={`validation-input ${
                    touched ? (error ? "invalid" : "valid") : ""
                }`} //only apply color when touched
                placeholder={placeholder}
                value={value}
                onChange={handleInputChange}
                onBlur={() => setTouched(true)}  //keep color by loose focus
            />
            <span className={`validation-input-icon ${error ? "" : "valid"}`}>
        {error ? "❌" : value ? "✅" : ""}
      </span>
            {error && <div className="validation-error-tooltip">{error}</div>}
        </div>
    );
};

export default ValidationInput;
