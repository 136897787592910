import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import ConfirmOrderScreenMobile from "./ConfirmOrderScreenMobile";
import ConfirmOrderScreenDesktop from "./ConfirmOrderScreenDesktop";
import {useWindowWidth} from "../../breakpoints";
import {useCart} from "../../context/CartContext";
import {useAuth} from "../../context/AuthContext";
import {createOrder, releaseHarvests} from "../../Utils/DatabaseFetch";





export const ConfirmOrderScreen = () => {
    const screenWidth = useWindowWidth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { cart, clearCart, markItemAsExpired } = useCart();
    const { user } = useAuth();

    const [note, setNote] = useState(state.note || '');
    const [paymentMethod, setPaymentMethod] = useState('CASH'); // default
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorPopupMessage, setErrorPopupMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState("");
    const [isExpired, setIsExpired] = useState(false);

    const totalSum = state.totalSum;
    const totalWeight = state.totalWeight;


    //helper function, get min. expiryDate from all cartItems
    const getEarliestExpiryDateAndAllHarvestIds = (cartItems) => {
        let earliestDate = null;
        const allHarvestIds = [];

        cartItems.forEach((item) => {
            if (Array.isArray(item.harvestIds)) {
                allHarvestIds.push(...item.harvestIds);
            }
            if (Array.isArray(item.expiryDates) && item.expiryDates.length > 0) {
                const localMin = item.expiryDates.reduce((earliest, current) =>
                    new Date(earliest) < new Date(current) ? earliest : current
                );
                if (!earliestDate || new Date(localMin) < new Date(earliestDate)) {
                    earliestDate = localMin;
                }
            }
        });

        return { earliestDate, allHarvestIds };
    };

    const { earliestDate, allHarvestIds } = getEarliestExpiryDateAndAllHarvestIds(cart.items);

    //reset timer when earliestDate change
    useEffect(() => {
        if (earliestDate) {
            // earliestDate in future? reset timer
            if (new Date(earliestDate) > new Date()) {
                setIsExpired(false);
            } else {
                // already expired
                setTimeRemaining("Abgelaufen");
                setIsExpired(true);
            }
        }
    }, [earliestDate]);


    //timer logic
    useEffect(() => {
        if (!earliestDate || isExpired) return;

        function updateCountdown() {
            const now = new Date();
            const expiry = new Date(earliestDate);
            const diff = expiry - now;

            if (diff <= 0) {
                setTimeRemaining("Abgelaufen");
                setIsExpired(true);
                // release everything
               // releaseHarvests(allHarvestIds);
                // mark all cart items expired
                cart.items.forEach((item) => markItemAsExpired(item.productId));
            } else {
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                setTimeRemaining(`${minutes}m ${seconds}s`);
            }
        }

        updateCountdown();
        const timerId = setInterval(updateCountdown, 1000);

        return () => clearInterval(timerId);
    }, [earliestDate, isExpired, markItemAsExpired]);

    const handleConfirmOrder = async () => {
        if(user === null) {
            setErrorPopupMessage(
                "Bitte zuerst einloggen, bevor du eine Bestellung aufgibst."
            );
            setShowErrorPopup(true);
            return;
        }

        if(cart.items.length === 0) {
            setErrorPopupMessage(
                "Bitte lege zuerst Produkte in den Warenkorb, bevor du eine Bestellung aufgibst."
            );
            setShowErrorPopup(true);
            return;
        }

        //if there are expired items => error popup
        const anyExpiredItem = cart.items.some((item) => item.isExpired);
        if (isExpired || anyExpiredItem) {
            setErrorPopupMessage(
                "Deine Reservierung ist abgelaufen.\n" +
                "Du wirst zurück in den Warenkorb geleitet, um deine Produkte wieder zu erwerben.\n" +
                "Bitte bestelle erst noch einmal, wenn deine Reservierung nicht abgelaufen ist."
            );
            setShowErrorPopup(true);
            return;
        }

        setIsLoading(true);
        try {
            //avoid blank messages
            const sanitizedNote = note && note.trim().length > 0 ? note : null;

            const orderRequest = {
                order: {
                    customerId: Number(user.customerId),
                    date: new Date().toISOString().split('T')[0],
                    state: 'PENDING',
                    weightG: totalWeight,
                    price: totalSum,
                    customerNote: sanitizedNote,
                    paymentMethod: paymentMethod,
                },
                productOrders: cart.items.map((item) => ({
                    productId: item.productId,
                    weightG: item.quantity,
                    harvestIds: item.harvestIds,
                    mushroomSpecies: item.mushroomSpecies,
                })),
            };

            await createOrder(orderRequest);
            setIsLoading(false);
            setShowThankYouPopup(true);
            clearCart();
        } catch (error) {
            setIsLoading(false);
            console.error('Fehler beim Erstellen der Bestellung:', error);
            setErrorPopupMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
            setShowErrorPopup(true);
        }
    };

    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
        setErrorPopupMessage("");

        if(isExpired){
            navigate('/shoppingcart-screen');
        }
    };

    const handleCloseThankYouPopup = () => {
        setShowThankYouPopup(false);
        navigate('/start-screen');
    };

    if (isLoading) {
        return <div className="confirm-order-loading">Lädt...</div>;
    }

    const commonProps = {
        cart,
        note,
        setNote,
        totalSum,
        totalWeight,
        paymentMethod,
        setPaymentMethod,
        handleConfirmOrder,
        showThankYouPopup,
        onCloseThankYouPopup: handleCloseThankYouPopup,
        showErrorPopup,
        errorPopupMessage,
        handleCloseErrorPopup,
        navigate,
        timeRemaining,
        isExpired
    };

    return (
        <>
            {screenWidth < 1024 ? (
                <ConfirmOrderScreenMobile {...commonProps} />
            ) : (
                <ConfirmOrderScreenDesktop {...commonProps} />
            )}
        </>
    );
};
