import React from "react";
import "./ErrorPopupConfigurableButtons.css";

export const ErrorPopupConfigurableButtons = ({ message, onClose, buttons = [] }) => {
    return (
        <div className="error-popup-overlay" onClick={onClose}>
            <div className="error-popup-container" onClick={(e) => e.stopPropagation()}>
                <button className="error-popup-close" onClick={onClose} aria-label="Schließen">&times;</button>
                <div className="error-popup-icon">
                    {/* warn icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" viewBox="0 0 24 24">
                        <path d="M1 21h22L12 2 1 21zM13 18h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
                    </svg>
                </div>
                <div className="error-popup-message">
                    <p>{message}</p>
                </div>
                <div className="error-popup-buttons">
                    {buttons.map((btn, index) => (
                        <button key={index} className="error-popup-button" onClick={btn.action}>
                            {btn.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
