import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage,  } from "firebase/messaging";

// https://firebase.google.com/docs/web/setup#available-libraries

// firebase config
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
