import { fetchOrdersByTimeRange} from "../../Utils/DatabaseFetch";
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import {useAuth} from "../../context/AuthContext";
import {useOrders} from "../../context/OrderContext";
import {matchesDate} from "../../Utils/normalizeDateInput";

import MyOrderScreenMobile from "./MyOrderScreenMobile";
import MyOrderScreenDesktop from "./MyOrderScreenDesktop";
import {useWindowWidth} from "../../breakpoints";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


export const MyOrderScreen = () => {
    const screenWidth = useWindowWidth();
    const { user } = useAuth();
    const [filterOption, setFilterOption] = useState("30Days"); // default
    const [stateFilterOption, setStateFilterOption] = useState("ALL");
    const [yearOptions, setYearOptions] = useState([]); // dynamic year options
    const [searchTerm, setSearchTerm] = useState("");
    const { orders: contextOrders, setOrders } = useOrders();
    const navigate = useNavigate();

    const { data: orders, loading, errorMessage, setErrorMessage } = useFetchWithErrorHandling(
        () => fetchOrdersByTimeRange(user.customerId, filterOption),
        [user?.customerId, filterOption]
    );




    useEffect(() => {
        if (orders && orders !== contextOrders) {
            setOrders(orders); // save in context only when changed
        }
    }, [orders, contextOrders, setOrders]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 2024; year <= currentYear; year++) {
            years.push(year);
        }
        setYearOptions(years);
    }, []);


    const filteredOrders = orders
        ? orders
            .filter((order) => {
                // State dropdown
                const matchesDropdownState =
                    stateFilterOption === "ALL" || stateFilterOption === order.state;
                if (!matchesDropdownState) {
                    return false;
                }

                // parse text filter
                const subTerms = searchTerm
                    .toLowerCase()
                    .split(",")
                    .map((t) => t.trim())
                    .filter((t) => t.length > 0);

               // no input, return true
                if (subTerms.length === 0) {
                    return true;
                }

                //check Date from textField
                const matchesOrderDate = subTerms.some((subTerm) =>
                    matchesDate(order.date, subTerm)
                );

                //check species
                const matchesMushroomSpecies = order.productOrders.some((product) => {
                    const speciesLC = product.mushroomSpecies.toLowerCase();
                    return subTerms.some((subTerm) => {
                        // exact match
                        if (speciesLC === subTerm) {
                            return true;
                        }
                        // consider the first 3 letters
                        const partialTerm = subTerm.slice(0, 3);
                        return speciesLC.includes(partialTerm);
                    });
                });

                // date or species
                return matchesOrderDate || matchesMushroomSpecies;
            })
            // sort latest orders first
            .sort((a, b) => b.orderId - a.orderId)
        : [];

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const handleStateFilterChange = (event) => {
        setStateFilterOption(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleCloseErrorPopup = () => setErrorMessage(null);

    const handleBackClick = () => {
        navigate("/account-screen");
    };



    return screenWidth < 1024 ? (
        <MyOrderScreenMobile
            loading={loading}
            errorMessage={errorMessage}
            filteredOrders={filteredOrders}
            handleFilterChange={handleFilterChange}
            handleStateFilterChange={handleStateFilterChange}
            handleSearchChange={handleSearchChange}
            handleCloseErrorPopup={handleCloseErrorPopup}
            searchTerm={searchTerm}
            filterOption={filterOption}
            stateFilterOption={stateFilterOption}
            yearOptions={yearOptions}
        />
    ) : (
        <MyOrderScreenDesktop
            loading={loading}
            errorMessage={errorMessage}
            filteredOrders={filteredOrders}
            handleFilterChange={handleFilterChange}
            handleSearchChange={handleSearchChange}
            handleStateFilterChange={handleStateFilterChange}
            handleCloseErrorPopup={handleCloseErrorPopup}
            handleBackClick={handleBackClick}
            searchTerm={searchTerm}
            filterOption={filterOption}
            stateFilterOption={stateFilterOption}
            yearOptions={yearOptions}
        />
    );
};
