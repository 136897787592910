import React from 'react';
import { NavigationbarTop } from '../../components/NavigationbarTop';
import { ShoppingCartScroll } from '../../components/ShoppingCartScroll';
import './ShoppingcartScreenDesktop.css';
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {Footer} from "../../components/Footer/Footer";
import {ExpiredItemsPopup} from "../../components/Utils/ExpiredItemsPopup";
import {PopupAvailable} from "../PopupAvailable";

export default function ShoppingcartScreenDesktop(props) {
    const {
        cart,
        removeFromCart,
        addToCart,
        clearCart,
        totalSum,
        totalWeight,
        note,
        setNote,
        handlePlaceOrder,
        navigate,
        showErrorPopup,
        errorPopupMessage,
        handleCloseErrorPopup,
        showExpiredPopup,
        expiredItems,
        setShowExpiredPopup,
        handleExpiredOption,
        handleRemoveExpiredItem,
        handleRemoveAllExpiredItems,
        showSuggestedWeightsPopup,
        selectedItem,
        handleSuggestedWeightsSelection,
        setShowSuggestedWeightsPopup,
        showThankYouPopup,
        setShowThankYouPopup,
    } = props;

    return (
        <>
            <NavigationbarTop />
        <div className="shoppingcart-screen-desktop">

            {showErrorPopup && (
                <ErrorPopup
                    message={errorPopupMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}

            {showExpiredPopup && (
                <ExpiredItemsPopup
                    items={expiredItems}
                    onClose={() => setShowExpiredPopup(false)}
                    onItemRequest={handleExpiredOption}
                    onItemRemove={handleRemoveExpiredItem}
                    onRemoveAll={handleRemoveAllExpiredItems}
                />
            )}

            {/* Suggested weights popup */}
            {showSuggestedWeightsPopup && selectedItem && (

                <PopupAvailable
                    suggestedWeights={selectedItem.suggestedWeights}
                    productId={selectedItem.productId}
                    mushroomSpecies={selectedItem.mushroomSpecies}
                    price={selectedItem.price}
                    onClose={() => setShowSuggestedWeightsPopup(false)}
                    onWeightSelect={handleSuggestedWeightsSelection}
                />
            )}



            <div className="shoppingcart-screen-desktop-content">
                <div className="cart-items-section">
                    <h2>Warenkorb</h2>
                    <ShoppingCartScroll
                        cartItems={cart.items}
                        removeFromCart={removeFromCart}
                        addToCart={addToCart}
                    />
                </div>
                <div className="order-summary-section">
                    <div className="note-field">
                    <textarea
                        placeholder="Anmerkungen zur Bestellung hinzufügen..."
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    </div>
                    <div className="desktop_summary">
                        <div className="desktop_summary-row">
                            <p className="desktop_total-label">Gesamtgewicht:</p>
                            <p className="desktop_total-amount">{totalWeight} g</p>
                        </div>
                        <div className="desktop_summary-row">
                            <p className="desktop_total-label">Gesamtsumme:</p>
                            <p className="desktop_total-amount">{totalSum} €</p>
                        </div>
                    </div>
                    <div className="action-buttons">
                        <button
                            className="continue-shopping-button"
                            onClick={() => navigate('/start-screen')}
                        >
                            Weiter shoppen
                        </button>
                        <button
                            className="order-button"
                            onClick={handlePlaceOrder}
                        >
                            Zur Kasse gehen
                        </button>
                    </div>
                </div>
            </div>

            {showThankYouPopup && (
                <div className="thank-you-popup-overlay">
                    <div className="thank-you-popup">
                        <p>Danke für deine Bestellung!</p>
                        <button
                            className="continue-shopping-button"
                            onClick={() => {
                                setShowThankYouPopup(false);
                                navigate('/start-screen')
                            }}

                        >
                            Weiter shoppen
                        </button>
                    </div>
                </div>
            )}


            <Footer/>

        </div>
        </>
    );
}
