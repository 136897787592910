import { useState, useEffect } from "react";
import {useAuth} from "../../context/AuthContext";
import {fetchProductNames, updateNotificationSettingsOnServer} from "../../Utils/DatabaseFetch";
import {useNavigate} from "react-router-dom";


export const useNotificationSettings = () => {
    const { user, setNotificationSettings } = useAuth();

    const [isMushroomIntervalExpanded, setIsMushroomIntervalExpanded] = useState(false);
    const [productList, setProductList] = useState([]);
    const [favoriteSelections, setFavoriteSelections] = useState({});
    const [reservationSelections, setReservationSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    //initialize favorites and reservation, whenever user.notifcationSettings changes
    useEffect(() => {
        if (user?.notificationSettings?.favoriteMushrooms) {
            initializeFavorites();
        }
        if (user?.notificationSettings?.reservation) {
            initializeReservations();
        }
    }, [user.notificationSettings]);

    const initializeFavorites = async () => {
        try {
            const products = await fetchProductNames();
            setProductList(products);

            const initialFavorites = products.reduce((acc, product) => {
                acc[product.mushroomSpecies] = user?.notificationSettings?.favoriteMushroomsList?.includes(product.mushroomSpecies) || false;
                return acc;
            }, {});
            setFavoriteSelections(initialFavorites);
        } catch (error) {
            console.error("Fehler beim Abrufen der Produktliste:", error);
        }
    };

    const initializeReservations = () => {
        const initialReservations = user?.notificationSettings?.reservationList || [];
        setReservationSelections(Array.isArray(initialReservations) ? initialReservations : []);
    };

    const handleIntervalToggle = async (key, isActive) => {
        if (isActive) {
            // only one interval can be active
            const otherKey = key === "mushroomWhisperer" ? "mushroomCravings" : "mushroomWhisperer";
            const updatedSettings = { [key]: true, [otherKey]: false };

            await saveSettings(updatedSettings);
        } else {
            // switch off
            const updatedSettings = { [key]: false };
            await saveSettings(updatedSettings);
        }
    };

    const handleMushroomToggle = async (key, isActive) => {
        const updatedSettings = { [key]: isActive };

        if (isActive) {
            const otherKey = key === "allMushrooms" ? "favoriteMushrooms" : "allMushrooms";
            updatedSettings[otherKey] = false;
        }

        await saveSettings(updatedSettings);
    };

    const handleFavoriteToggle = async (mushroomSpecies) => {
        const updatedSelections = {
            ...favoriteSelections,
            [mushroomSpecies]: !favoriteSelections[mushroomSpecies],
        };
        setFavoriteSelections(updatedSelections);

        const selectedFavorites = Object.entries(updatedSelections)
            .filter(([, isSelected]) => isSelected)
            .map(([name]) => name);

        const updatedSettings = { favoriteMushroomsList: selectedFavorites };
        await saveSettings(updatedSettings);
    };

    const handleReservationToggle = async (mushroomSpecies, date) => {
        const isAlreadySelected = reservationSelections.some(
            (item) => item.mushroomSpecies === mushroomSpecies && item.date === date
        );

        const updatedSelections = isAlreadySelected
            ? reservationSelections.filter(
                (item) => !(item.mushroomSpecies === mushroomSpecies && item.date === date)
            )
            : [...reservationSelections, { mushroomSpecies, date }];

        setReservationSelections(updatedSelections);

        const updatedSettings = { reservationList: updatedSelections };
        await saveSettings(updatedSettings);
    };

    const saveSettings = async (updatedSettings) => {
        try {
            setIsLoading(true);
            const response = await updateNotificationSettingsOnServer(user.customerId, updatedSettings);
            setNotificationSettings(response);
        } catch (error) {
            console.error("Fehler beim Speichern der Einstellungen:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };


    return {
        user,
        isMushroomIntervalExpanded,
        setIsMushroomIntervalExpanded,
        productList,
        favoriteSelections,
        reservationSelections,
        isLoading,
        handleIntervalToggle,
        handleMushroomToggle,
        handleFavoriteToggle,
        handleReservationToggle,
        handleBackClick,
    };
};
