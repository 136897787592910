import React from "react";
import "./style.css";

export const OysterIcon = ({ className }) => {
  return (
    <div className={`oyster-icon ${className}`}>
      <img className="img-3" alt="Oyster icon" src="/img/oyster-icon-1-3.png" />
    </div>
  );
};
