import React, { createContext, useContext, useReducer, useEffect } from "react";
import { getMessaging, getToken, deleteToken } from "firebase/messaging";
import {
    fetchNotificationSettings,
    removeTokenFromServer,
    sendTokenToServer,
    updateNotificationSettingsOnServer
} from "../Utils/DatabaseFetch";

// initial
const initialState = {
    user: null,
    firebaseToken: null,
    notificationSettings: {},
};

// Actions
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const UPDATE_USER = "UPDATE_USER";
const SET_FIREBASE_TOKEN = "SET_FIREBASE_TOKEN";
const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";
let isTokenSyncInProgress = false;  //this boolean ensures that login and sycnFirebaseToken are not triggered both at the same time


// Reducer
function authReducer(state, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload, // set use in state
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
                firebaseToken: null,// remove user from state
            };
        case UPDATE_USER:
            return { ...state, user: { ...state.user, ...action.payload } };
        case SET_FIREBASE_TOKEN:
            return {
                ...state,
                firebaseToken: action.payload
            };
        case UPDATE_NOTIFICATION_SETTINGS:
            return {
                ...state,
                user: {
                    ...state.user,
                    notificationSettings: {
                        ...state.user.notificationSettings,
                        ...action.payload,
                    },
                },
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
// Context
export const AuthContext = createContext(undefined);

// Provider
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState, () => {
        const localData = localStorage.getItem("user");
        return localData ? { user: JSON.parse(localData), firebaseToken: null } : initialState;
    });

    // save in local storage
    useEffect(() => {
        if (state.user) {
            localStorage.setItem("user", JSON.stringify(state.user));
        } else {
            localStorage.removeItem("user");
        }
    }, [state.user]);




    // token synchronization
    useEffect(() => {
        let intervalId;

        const monitorTokenUpdates = async () => {
            if (isTokenSyncInProgress || !state.user || !state.user.customerId || state.firebaseToken) return;
            isTokenSyncInProgress = true;
            console.log("Trigger Token-Sync for user:", state.user?.customerId);


            if (state.user && !state.firebaseToken) {
                const customerId = state.user.customerId?.customerId || state.user.customerId;

                if (!customerId) {
                    console.error("Ungültige customerId:", state.user);
                    return;
                }

                if (!customerId) {
                    console.error("Keine gültige customerId gefunden:", state.user);
                    return;
                }


                try {
                    const messaging = getMessaging();
                    const newToken = await getToken(messaging, {vapidKey: "BOuJpOOhIlgm4XpMNLwFCZoRUZVTISO5T8Uzf6R6lRx1cdY_ba1dOjKJS7_s_M0JUEPEB5aNN3fX1azitkvYKuM", forceRefresh: true });

                    if (newToken && newToken !== state.firebaseToken) {

                        console.log("Neuer Token erkannt:", newToken);

                        // send token to server
                        await sendTokenToServer(state.user.customerId, newToken);

                        // actualize state
                        dispatch({type: SET_FIREBASE_TOKEN, payload: newToken});
                    }
                } catch (error) {
                    console.error("Fehler beim Abrufen des Firebase-Tokens:", error);
                } finally {
                    isTokenSyncInProgress = false;
                }
            }
        };

        if (state.user) {
            // initial token sync
            monitorTokenUpdates();

            // set up interval to monitor token updates every 60 minutes
            intervalId = setInterval(monitorTokenUpdates, 60 * 60 * 1000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [state.user, state.firebaseToken]);


    // actions
    const login = async (user) => {
        isTokenSyncInProgress = true;
        const sanitizedUser = {
            ...user,
            customerId: user.customerId?.customerId || user.customerId,
        };

        //localStorage.setItem("jwtToken", user.token);


        // get firebase token after login
        try {
            // fetch notification settings
            const settings = await fetchNotificationSettings(sanitizedUser.customerId);
            console.log(sanitizedUser.customerId, "Notification settings fetched");
            console.log("Notification settings:", settings);


            // dispatch user and notification settings
            dispatch({
                type: LOGIN,
                payload: {
                    ...sanitizedUser,
                    notificationSettings: settings || {}, // fallback
                },
            });

            // fetch firebase token
            console.log("Trigger Token-Sync for user, from login AuthContext");
            const messaging = getMessaging();
            let token = await getToken(messaging, {
                vapidKey: "BOuJpOOhIlgm4XpMNLwFCZoRUZVTISO5T8Uzf6R6lRx1cdY_ba1dOjKJS7_s_M0JUEPEB5aNN3fX1azitkvYKuM",
                forceRefresh: true
            });
            const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

            if (token) {
                try {
                    // send to server
                    await sendTokenToServer(sanitizedUser.customerId, token, isIOS);
                    dispatch({ type: SET_FIREBASE_TOKEN, payload: token });
                    console.log("Token erfolgreich an den Server gesendet und im Zustand aktualisiert.");
                } catch (error) {
                    if (error.response && error.response.status === 409) {
                        console.warn("Token bereits registriert. Versuche, den Token zu erneuern.");
                        // delete token
                        await deleteToken(messaging);
                        console.log("Existierender Token gelöscht.");

                        // get new token
                        token = await getToken(messaging, {
                            vapidKey: "BOuJpOOhIlgm4XpMNLwFCZoRUZVTISO5T8Uzf6R6lRx1cdY_ba1dOjKJS7_s_M0JUEPEB5aNN3fX1azitkvYKuM",
                            forceRefresh: true
                        });

                        if (token) {
                            //send again
                            await sendTokenToServer(sanitizedUser.customerId, token, isIOS);
                            dispatch({ type: SET_FIREBASE_TOKEN, payload: token });
                            console.log("Neuer Token erfolgreich an den Server gesendet und im Zustand aktualisiert.");
                        }
                    } else {
                        throw error;
                    }
                }
            }
        } catch (error) {
            console.error("Fehler beim Abrufen oder Senden des Firebase-Tokens nach Login:", error);
        }
        finally {
            isTokenSyncInProgress = false;
        }
    };

    const logout = async () => {
        if (state.firebaseToken) {
            try {
                await removeTokenFromServer(state.user.customerId, state.firebaseToken);

                //delete token from firebase
                const messaging = getMessaging();
                await deleteToken(messaging);
                console.log("Firebase-Token erfolgreich gelöscht.");
            } catch (error) {
                console.error("Fehler beim Entfernen des Tokens vom Server:", error);
            }
        }

        //remove jwt token from local storage
        localStorage.removeItem("jwtToken");

        //reset user state
        dispatch({type: LOGOUT});
    };

    const setUser = (updatedUser) => {
        dispatch({ type: UPDATE_USER, payload: updatedUser });
    };

    const setNotificationSettings = (settings) => {
        dispatch({ type: UPDATE_NOTIFICATION_SETTINGS, payload: settings });
    };

    const updateReservationList = async (newReservationList) => {
        const currentSettings = state.user.notificationSettings;

        // local update
        const updatedSettings = {
            ...currentSettings,
            reservationList: newReservationList,
        };

        dispatch({
            type: UPDATE_NOTIFICATION_SETTINGS,
            payload: updatedSettings,
        });

        try {
            // update on backend (whole notification settings)
            await updateNotificationSettingsOnServer(state.user.customerId, updatedSettings);
        } catch (error) {
            console.error("Fehler beim Aktualisieren der NotificationSettings im Backend:", error);
        }
    };

    return (
        <AuthContext.Provider value={{ user: state.user, login, logout, setUser, setNotificationSettings, updateReservationList }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom Hook
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

