import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {sendFeedback} from "../../Utils/DatabaseFetch";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {SuccessPopup} from "../../components/Utils/SuccessPopup";
import {handleFetchError} from "../../Utils/handleFetchError";
import FeedbackScreenDesktop from "./FeedbackScreenDesktop";
import FeedbackScreenMobile from "./FeedbackScreenMobile";
import {useWindowWidth} from "../../breakpoints";


export const FeedbackScreen = () => {
  const screenWidth = useWindowWidth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(1); // values from 1 to 5
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderNumberError, setOrderNumberError] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const emojiData = [
    { id: 1, label: "Worst", icon: "/img/worst.gif", staticIcon: "/img/worst-static.png" },
    { id: 2, label: "Not Good", icon: "/img/not-good.gif", staticIcon: "/img/not-good-static.png" },
    { id: 3, label: "Fine", icon: "/img/fine.gif", staticIcon: "/img/fine-static.png" },
    { id: 4, label: "Look Good", icon: "/img/look-good.gif", staticIcon: "/img/look-good-static.png" },
    { id: 5, label: "Very Good", icon: "/img/very-good.gif", staticIcon: "/img/very-good-static.png" },
  ];

  const validateOrderNumber = (value) => {
    const number = Number(value);
    return Number.isInteger(number) && number >= 0;
  }

  const handleSliderChange = (value) => {
    setSliderValue(value);
    setSelectedEmoji(value);
  };

  const handleEmojiClick = (id) => {
    setSliderValue(id);
    setSelectedEmoji(id);
  };

  const handleSendFeedback = async () => {

    const sanitizedFeedback = feedbackText && feedbackText.trim().length > 0 ? feedbackText : null;
    const feedbackData = {
      rating: sliderValue,
      feedback: sanitizedFeedback,
      orderId: Number(orderNumber.valueOf()) || null,
      customerId: user.customerId,
    };

    try {
      await sendFeedback(feedbackData);
      setSuccessMessage("Vielen Dank für dein Feedback!");
      setFeedbackText("");
      setOrderNumber("");
      setShowSuccessPopup(true);
    } catch (error) {
      const message = handleFetchError(error);
      setErrorMessage(message.message || "Fehler beim Senden des Feedbacks.");
      setShowErrorPopup(true);

    }
  };

  useEffect(() => {
    if (selectedEmoji !== null) {
      const timer = setTimeout(() => setSelectedEmoji(null), 3500); // Emoji stops animation after 3,5 seconds
      return () => clearTimeout(timer);
    }
  }, [selectedEmoji]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorMessage("");

  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    setSuccessMessage("");

  };

  const commonProps = {
    sliderValue,
    handleSliderChange,
    emojiData,
    handleEmojiClick,
    selectedEmoji,
    orderNumber,
    setOrderNumber,
    validateOrderNumber,
    feedbackText,
    setFeedbackText,
    handleSendFeedback,
    handleBackClick,
  };

  return (
      <>
        {screenWidth < 1024 ? (
            <FeedbackScreenMobile {...commonProps} />
        ) : (
            <FeedbackScreenDesktop {...commonProps} />
        )}
        {showErrorPopup && (
            <ErrorPopup message={errorMessage} onClose={handleCloseErrorPopup} />
        )}
        {showSuccessPopup && (
            <SuccessPopup message={successMessage} onClose={handleCloseSuccessPopup} />
        )}
      </>
  );
};
