// Footer.jsx
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./Footer.css";
import {useAuth} from "../../context/AuthContext";
import {ErrorPopup} from "../Utils/ErrorPopup";
import {ConfirmPopup} from "../Utils/ConfirmPopup";
import {ErrorPopupConfigurableButtons} from "../Utils/ErrorPopupConfigurableButtons";

export const Footer = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [redirectPath, setRedirectPath] = useState("");

    const handleProtectedLinkClick = (e, path) => {
        if (!user) {
            e.preventDefault();
            setRedirectPath(path);
            setShowErrorPopup(true);
        }
        //if user is logged in, linking is allowed
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Company Logo */}
                <div className="footer-logo">
                    <img
                       // src="/img/leonardo-phoenix-change-the-lettering-to-darkstone-mycology-as-0-7.png"
                        src="/img/Logo-9.png"
                        alt="Company Logo"
                        className="footer-company-logo"
                    />
                </div>

                {/* Footer Sections */}
                <div className="footer-sections">
                    <div className="footer-section">
                        <h3>Account</h3>
                        <ul>
                            <li>
                                <Link
                                    to="/account-screen"
                                    className="footer-link"
                                    onClick={(e) => handleProtectedLinkClick(e, "/account-screen")}
                                >
                                    Profil
                                </Link>
                            </li>
                            <li>
                                <Link to="/shoppingcart-screen">Warenkorb</Link>
                            </li>
                            <li>
                                <Link
                                    to="/myorder-screen"
                                    className="footer-link"
                                    onClick={(e) => handleProtectedLinkClick(e, "/myorder-screen")}
                                >
                                    Bestellungen
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/settings?scrollTo=favorites"
                                    className="footer-link"
                                    onClick={(e) => handleProtectedLinkClick(e, "/settings?scrollTo=favorites")}
                                >
                                    Favoriten
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/notifications"
                                    className="footer-link"
                                    onClick={(e) => handleProtectedLinkClick(e, "/notifications")}
                                >
                                    Benachrichtigungen
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Nützliches</h3>
                        <ul>
                            <li><Link to="/about-us">Über mich</Link></li>
                            <li><Link to="/contact">Kontakt</Link></li>
                            <li><Link to="/recipes">Rezepte</Link></li>
                            <li><Link to="/promotions">Promotions</Link></li>
                            <li><Link to="/new-arrivals">Neuheiten</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Hilfe Center</h3>
                        <ul>
                            <li><Link to="/payment-options">Zahlungsmöglichkeiten</Link></li>
                            <li><Link to="/pickups">Abholungen</Link></li>
                            <li><Link to="/faq">Q&A</Link></li>
                            <li><Link to="/business-customers">Business Kunden</Link></li>
                            <li><Link to="/desktop-impressum">Impressum</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

                {/* Error Popup */}
                <div className="footer-error-popup">
                {showErrorPopup && (
                    <ErrorPopupConfigurableButtons
                    message="Bitte melde dich zuerst an, um auf diese Seite zuzugreifen."
                    onClose={() => setShowErrorPopup(false)}
                    buttons={[
                        {
                            label: "Zur Anmeldung",
                            action: () => {
                                setShowErrorPopup(false);
                                navigate("/login-screen");
                            },
                        },
                        {
                            label: "Abbrechen",
                            action: () => setShowErrorPopup(false),
                        },
                    ]}
                />
            )}
            </div>

        </footer>
    );
};
