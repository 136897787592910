//database fetches
import {handleFetchError} from "./handleFetchError";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_PUBLIC_URL = process.env.REACT_APP_API_BASE_PUBLIC_URL;


export const fetchProducts = async () => {
    try {
          const response = await fetch(API_BASE_PUBLIC_URL + '/product');

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen Produkte: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching products:', error);
      // throw new Error(error.message ||"Error fetching products:");
        handleFetchError(error);
        throw error;
    }
};


export const fetchSuggestedWeights = async (productId, targetWeight, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/product/suggested-weights?productId=${productId}&targetWeight=${targetWeight}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
    },
    });


        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    console.warn("Token abgelaufen. Versuche, den Token zu aktualisieren...");
                    await refreshAccessToken();
                    return fetchSuggestedWeights(productId, targetWeight,true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            } else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }



        if (!response.ok) {
            let errorMessage = "Ein Fehler ist beim Abrufen der Daten aufgetreten.";
            try {
                const errorData = await response.json();
                errorMessage = errorData.message || errorMessage;
            } catch {
                console.error("Fehler beim Parsen der Fehlerantwort.");
            }
            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        handleFetchError(error);
        throw error;
    }
};

export const fetchProductById = async (productId) => {
    try {
        const response = await fetch(`${API_BASE_PUBLIC_URL}/product/${productId}`);
        if (!response.ok) {
            throw new Error("Produkt nicht gefunden.");
        }
        return await response.json();
    } catch (error) {
        console.error("Fehler beim Abrufen des Produkts:", error);
        throw error;
    }
};


export const reserveHarvest = async (harvestIds, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/harvest/reserve-harvest`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(harvestIds),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    console.warn("Token abgelaufen. Versuche, den Token zu aktualisieren...");
                    await refreshAccessToken();
                    return reserveHarvest(harvestIds, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }


        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Fehler bei der Reservierung der Ernte");
        }

        return await response.json();
    } catch (error) {
        //console.error("Fehler beim Reservieren der Ernte:", error);
     //   throw new Error(error.message || "Fehler beim Reservieren der Ernte");
        handleFetchError(error);
        throw error;
    }
};


export const releaseHarvests = async (harvestIds, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/harvest/release-harvests`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(harvestIds)
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return releaseHarvests(harvestIds, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }

        }


        if (!response.ok)   {
            const errorData = await response.json();
            throw new Error(errorData.message ||"Fehler beim Freigeben der Ernte");
        }


        console.log("Ernten wurden freigegeben.");
    } catch (error) {
        //console.error("Fehler beim Freigeben der Ernte:", error);
        //throw new Error(error.message ||"Fehler beim Freigeben der Ernte:");
        handleFetchError(error);
        //throw error;          //ignore this, because backend scheduled handle it anyway, no impact user experience
    }
};


export const createOrder = async (orderRequest, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/order/place-order`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(orderRequest),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return createOrder(orderRequest, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }

        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Erstellen der Bestellung: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        return await response.json();

    }
    catch (error) {
        console.error("Fehler beim Erstellen der Bestellung:", error);
        throw error;
    }
}


export const actualizeRemainingWeightForProduct = async (productId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");

    try {
        const response = await fetch(`${API_BASE_URL}/product/${productId}/actualize-remaining-weight`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return actualizeRemainingWeightForProduct(productId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }

        }

        const message = await response.text();
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to create account");
        }
    } catch (error) {
        console.error(error.message || "Network error:", error);
    }
};

export const createAccount = async (accountData) => {
    try {
        const response = await fetch(`${API_BASE_PUBLIC_URL}/customer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(accountData),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim erstellen des Accounts: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        // check if answer is json
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();

        }
    } catch (error) {
        throw new Error(error.message ||"Fehler bei der Registrierung.");
    }
};


export const loginUser = async (loginData) => {
    try {
        const response = await fetch(`${API_BASE_PUBLIC_URL}/customer/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",   //for the refresh token cookie
            body: JSON.stringify(loginData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Login fehlgeschlagen");
        }

        const data = await response.json();
        const { token, ...userData } = data; // split token from rest of the data
        return { userData, token };

    } catch (error) {
        throw new Error(error.message || "Fehler beim Einloggen.");
    }
};


export const fetchLastOrderProducts = async (customerId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/order/last-order?customerId=${customerId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    console.warn("Token abgelaufen. Versuche, den Token zu aktualisieren...");
                    await refreshAccessToken();

                    return fetchLastOrderProducts(customerId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der letzten Order: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        // check if answer is json
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            const textResponse = await response.text();
            if (textResponse === "Noch keine Bestellung vorhanden") {
                return null;
            }
            throw new Error("Unerwartete Antwort vom Server");
        }
    } catch (error) {
        throw error;
    }
};


export const fetchOrdersByTimeRange = async (customerId, timeRange, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {

        const response = await fetch(
            `${API_BASE_URL}/order/by-period?customerId=${customerId}&period=${timeRange}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return fetchOrdersByTimeRange(customerId, timeRange, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                    // refresh token is invalid
                    throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
                }
            }

        //if no order where found, ignore the messages
        if (response.status === 404) {
            return []; // empty response
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Bestellungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const data = await response.json();


        return Object.values(data);
    } catch (error) {
        if (error.name === 'TypeError') {

            error.name = 'NetworkError';
        }
        throw error;
    }
}

export const fetchOrderDetail = async (orderId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {

        const response = await fetch(
            `${API_BASE_URL}/order/my-order?orderId=${orderId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return fetchOrderDetail(orderId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                    // refresh token is invalid
                    throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
                }
            }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Bestellungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const data = await response.json();


        return data;
    } catch (error) {
        if (error.name === 'TypeError') {

            error.name = 'NetworkError';
        }
        throw error;
    }
}


export const sendTokenToServer = async (customerId, token, refreshAttempted = false) => {
    const jwtToken = localStorage.getItem("jwtToken");
    try {

        const payload = { customerId, token};
        //console.log("Daten an das Backend:", JSON.stringify( payload));
        const response = await fetch(`${API_BASE_URL}/notifications/register-token`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return sendTokenToServer(customerId, token, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }

            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        console.log("Im FetcheTokenToServer: " + JSON.stringify(payload));


        if (response.ok) {
            console.log("Token erfolgreich an Server gesendet.");
        } else {
            console.error("Fehler beim Senden des Tokens:", await response.text());
        }
    } catch (error) {
        console.error("Fehler beim Senden des Tokens:", error);
    }
};

export const removeTokenFromServer = async (customerId, token, refreshAttempted = false) => {
    const jwtToken = localStorage.getItem("jwtToken");
    try {
        const payload = { customerId, token};
        const response = await fetch(`${API_BASE_URL}/notifications/remove-token`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return removeTokenFromServer(customerId, token, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }

            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Löschen des Token: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    }
    catch (error) {
        throw error;
    }

}



export const fetchAccountDetails = async (customerId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return fetchAccountDetails(customerId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }


        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Account Informationen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Fehler beim Abrufen der Kontoinformationen:", error);
        //handleFetchError(error);
        throw error;
        //throw new Error(error.message || "Fehler beim Abrufen der Kontoinformationen.");
    }
};


export const updateAccount = async (customerId, updatedData, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return updateAccount(customerId, updatedData, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }

            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim aktualsieren der Account Daten: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Fehler beim Aktualisieren der Kontoinformationen:", error);
        //throw new Error(error.message || "Fehler beim Aktualisieren der Kontoinformationen.");
        handleFetchError(error);
        throw error;
    }
};

export const changePassword = async (customerId, updatedData, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/customer/change-password/${customerId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return changePassword(customerId, updatedData,true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }

            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }


        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData || "Fehler beim Aktualisieren des Passworts.");
        }

        //standard return from backend is text
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    } catch (error) {
        console.error("Fehler beim Aktualisieren des Passworts:", error);
        throw new Error(error.message || "Fehler beim Aktualisieren des Passworts.");
    }
};

export const sendFeedback = async (feedbackData, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/feedback`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return sendFeedback(feedbackData, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }


        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Senden des Feedbacks: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        //handleFetchError(error);
        throw error;
    }
};


export const updateNotificationSettingsOnServer = async (customerId, settings, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/notification-settings/by-customer/${customerId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settings),
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return updateNotificationSettingsOnServer(customerId, settings,true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }


        if (!response.ok) {
            throw new Error("Fehler beim Aktualisieren der Benachrichtigungseinstellungen.");
        }

        return await response.json();
    } catch (error) {
        console.error("Error updating notification settings:", error);
        throw error;
    }
};


export const fetchNotificationSettings = async (customerId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    console.log("fetchNotificationSettings", customerId);
    console.log("Authorization Header:", `Bearer ${token}`);
    try {
        const response = await fetch(`${API_BASE_URL}/notification-settings/by-customer/${customerId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    console.warn("JWT abgelaufen, Token wird erneuert...");
                    await refreshAccessToken();
                    return fetchNotificationSettings(customerId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Notification Settings: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching notification settings:", error);
        throw error;
    }
};


export const fetchProductNames = async () => {
    try {
        const response = await fetch(`${API_BASE_PUBLIC_URL}/product/names`);

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Produktnamen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchForecastByProduct = async (productId) => {
    try {
        const response = await fetch(`${API_BASE_PUBLIC_URL}/harvest/forecast/${productId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim laden der Forecast: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        // Parse JSON response
        return await response.json();

    } catch (error) {
        console.error("Fehler beim Abrufen der Forecast:", error);
        throw error;
    }
};

export const cancelOrder = async (orderId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/order/cancel-order/${orderId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return cancelOrder(orderId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Stornieren der Bestellung: \n${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }

    }
    catch (error) {
        throw error;
    }

}

export const deleteAccount = async(customerId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/customer/${customerId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return deleteAccount(customerId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler löschen des Accounts: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
}

export const getNotifications = async (customerId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/notifications/customer/${customerId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return getNotifications(customerId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }
            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Abrufen der Benachrichtigungen: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
};

export const deleteNotification = async (notificationHistoryId, refreshAttempted = false) => {
    const token = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${API_BASE_URL}/notifications/customer/${notificationHistoryId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 403) {
            if (!refreshAttempted) {
                try {
                    await refreshAccessToken();
                    return deleteNotification(notificationHistoryId, true); // try again
                } catch (error) {
                    console.error("Fehler beim Aktualisieren des Tokens:", error);
                    throw error;
                }

            }
            else {
                // refresh token is invalid
                throw new Error("Token ist ungültig oder abgelaufen. Bitte melde dich erneut an.");
            }
        }

        if (!response.ok) {
            const errorMessage = await response.text();
            const error = new Error(`Fehler beim Löschen der Benachrichtigung: ${errorMessage}`);
            error.name = 'HttpError';
            error.status = response.status;
            throw error;
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    }
    catch (error) {
        throw error;
    }
};


const refreshAccessToken = async () => {
try {

    const response = await fetch(API_BASE_PUBLIC_URL + "/customer/refresh", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include", // send http cookie only

    });

    if (!response.ok) {
        throw new Error("Unable to refresh token");
    }

    const data = await response.json();
    const {token} = data;

    // store new token in localStorage.
    localStorage.setItem("jwtToken", token);
    }
    catch (error) {
        console.error("Fehler beim Aktualisieren des Tokens:", error);
    }
};












