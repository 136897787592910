import React from 'react';
import {WelcomeHeader} from "../../components/WelcomeHeader";
import {getStateDescription, getStatusIconPath} from "../../components/Utils/stateIconMapping";
import {LastOrderScrollView} from "../../components/LastOrderScrollView";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";
import './AccountScreenMobile.css';
import { useNavigate } from 'react-router-dom';



export default function AccountScreenMobile({
                                                user,
                                                lastOrderProducts,
                                                safeLastOrderProducts,
                                                showErrorPopup,
                                                errorMessage,
                                                handleLogout,
                                                initiateCancelOrder,
                                                handleCloseErrorPopup,
                                                handleShowHelp
                                            }) {

    const firstProduct = safeLastOrderProducts && safeLastOrderProducts.length > 0 ? safeLastOrderProducts[0] : null;
    const navigate = useNavigate();

    return (
        <div className="account-screen">

            <div className="welcome">
                <WelcomeHeader
                    className="welcome-header-instance"
                    currentScreen="/account-screen"
                />
            </div>

            <div className="account-mainframe">
                {/* Buttons */}
                <div className="account-buttons">
                    <button
                        className="account-button"
                        onClick={() => navigate("/myorder-screen")}
                    >
                        <span>Meine Bestellungen</span>
                    </button>

                    <button
                        className="account-button"
                        onClick={() => navigate("/feedbackscreen")}
                    >
                        <span>Feedback</span>
                    </button>

                    <button
                        className="account-button"
                        onClick={() => navigate("/my-account")}
                    >
                        <span>Mein Konto</span>
                    </button>


                    <button className="account-button" onClick={handleLogout}>
                        <span> Abmelden  </span>
                    </button>

                    <button className="account-button" onClick={handleShowHelp}>
                        <span>App-Anleitung</span>
                    </button>

                </div>

                {/* Last Order Section */}
                <div className="last-order-section">
                    <div className="last-order-header">
                        <h3>Zuletzt bestellt:</h3>
                        <img
                            src={getStatusIconPath(firstProduct?.state)}
                            alt="Status Icon"
                            className="status-icon"
                        />
                    </div>

                    <div>     {/* className="last-order-scrollview" */}
                        <LastOrderScrollView products={lastOrderProducts || []} />
                    </div>


                    <div className="last-order-status">
                        <div className="status">
                            <span>Status:</span>

                            <div className="status-details">

                            <span
                                className="status-text">{getStateDescription(firstProduct?.state)}</span>
                            </div>
                        </div>
                        <div className="order-summary">
                            <span>Gesamtsumme:</span>
                            <span>{safeLastOrderProducts[0]?.price ? `${firstProduct?.price.toFixed(2)} €` : "0,00 €"}</span>
                        </div>
                        <div className="order-summary">
                            <span>Bestellnummer:</span>
                            <span>{safeLastOrderProducts[0]?.orderId ? `${firstProduct?.orderId} ` : "0"}</span>
                        </div>
                        {/* cancel button only visible when pending state */}
                        {firstProduct?.state === 'PENDING' && (
                            <button
                                className="account-screen-mobile_cancel-button"
                                onClick={() => initiateCancelOrder(firstProduct?.orderId)}
                            >
                                Bestellung stornieren
                            </button>
                        )}
                    </div>
                </div>

                {showErrorPopup && (
                    <ErrorPopup
                        message={errorMessage}
                        onClose={handleCloseErrorPopup}
                    />
                )}

                <NavbarBottomPhone className="navbar-bottom-phone-instance"/>
            </div>
        </div>
    );



}
