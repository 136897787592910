import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App";


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${window.location.origin}/firebase-messaging-sw.js`)
        .then((registration) => {
            console.log('Service Worker registriert mit Scope:', registration.scope);

            // if controller changes (new version) refresh the site
           navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('Neuer Service Worker aktiv - lade Seite neu...');
                window.location.reload();
            });


        })
        .catch((err) => {
            console.error('Service Worker Registrierung fehlgeschlagen:', err);
        });


/*
    navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Nachricht vom Service Worker empfangen:', event.data);
        if (event.data && event.data.type === 'foregroundMessage') {
            const payload = event.data.data;

            window.dispatchEvent(new CustomEvent('swMessage', { detail: event.data }));


        }
    });
*/
}


const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<App />);
