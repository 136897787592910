import React from "react";
import { KingTrumpetIcon } from "../KingTrumpetIcon";
import { MushroomIcon } from "../MushroomIcon";
import { OysterIcon } from "../OysterIcon";
import { ShiitakeIcon } from "../ShiitakeIcon";
import "./style.css";


export const IconSlider = ({ onIconClick }) => {

    const filterOptions = [
        { label: "Alle",     icon: <MushroomIcon mushroomIcon="icon" /> },
        { label: "Shiitake", icon: <ShiitakeIcon className="icon" /> },
        { label: "Kräuters.", icon: <KingTrumpetIcon className="icon" /> },
        { label: "Seitlinge", icon: <OysterIcon className="icon" /> },
       /* { label: "Shiitake", icon: <ShiitakeIcon className="icon" /> },
        { label: "Kräuters.", icon: <KingTrumpetIcon className="icon" /> },
        { label: "Seitlinge", icon: <OysterIcon className="icon" /> }, */
    ];


    return (
        <div className="icon-slider-scroll-container">
            <div className="icon-slider-content">
                {filterOptions.map((option) => (
                    <div
                        key={option.label}
                        className="icon-option"
                        onClick={() => onIconClick(option.label)}
                    >
                        {option.icon}
                        <div className="icon-label">{option.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
