import React from "react";
import { Link } from "react-router-dom";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import "./LoginScreenDesktop.css";
import {Footer} from "../../components/Footer/Footer";
import {NavigationbarTop} from "../../components/NavigationbarTop";

export default function LoginScreenDesktop(props) {
    const {
        email, setEmail,
        password, setPassword,
        errorMessage, clearError,
        handleBackClick,
        handleLogin,
        handleShowHelp
    } = props;

    return (
        <>
            <NavigationbarTop/>
        <div className="login-desktop-layout">
            <aside className="login-desktop-aside">
                <div className="aside-header">
                    <h1>Willkommen</h1>
                    <p>Melde dich in deinem Konto an</p>
                </div>
                <button className="login-desktop_aside-back-button" onClick={handleBackClick}>
                    <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                    <span className="login-desktop_back-link-text">Zurück</span>
                </button>
            </aside>

            <div className="login-desktop-main">
                <h2 className="main-title">Anmelden</h2>
                <div className="desktop-input-container">
                    <input
                        type="email"
                        className="desktop-input-field"
                        placeholder=" "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="desktop-input-label">E-Mail</label>
                </div>

                <div className="desktop-input-container">
                    <input
                        type="password"
                        className="desktop-input-field"
                        placeholder=" "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className="desktop-input-label">Passwort</label>
                </div>

                <button className="login-button" onClick={handleLogin}>
                    Einloggen
                </button>

                <Link to="/register-screen" className="register-link">
                    Registrieren
                </Link>

                <button className="help-button" onClick={handleShowHelp}>
                    Wie mache ich daraus eine App?
                </button>

                {errorMessage && (
                    <ErrorPopup message={errorMessage} onClose={clearError}/>
                )}
            </div>
        </div>
            <Footer/>
        </>
    );
}
