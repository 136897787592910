import React from 'react';
import { WelcomeHeader } from "../../components/WelcomeHeader";
import { IconSlider } from "../../components/IconSlider";
import { NavbarBottomPhone } from "../../components/NavbarBottomPhone";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import { ProductScrollView } from "../../Utils/ProductScrollView";
import "./StartScreenMobile.css";

export default function StartScreenMobile(props) {
    const {
        selectedMushroomType,
        setSelectedMushroomType,
        searchText,
        setSearchText,
        availabilityFilter,
        setAvailabilityFilter,
        loading,
        showLoadingMessage,
        filteredProducts,
        showErrorPopup,
        errorMessage,
        handleCloseErrorPopup,
    } = props;

    return (
        <>
            <div className="site-frame">
                <WelcomeHeader
                    className="welcome-header-instance"
                    currentScreen="/start-screen"
                />
                <IconSlider onIconClick={(type) => setSelectedMushroomType(type)} />
                <div className="searchbar">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Suche..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>

                <div className="dropdown-filter">
                    <div className="frame-24">
                        <div className="start-screen-mobile-filter-header">Pilzauswahl</div>
                    </div>
                    <div className="frame-24">
                        <select
                            className="filter-dropdown-start-screen-mobile"
                            value={availabilityFilter}
                            onChange={(e) => setAvailabilityFilter(e.target.value)}
                        >
                            <option value="Alle">Alle</option>
                            <option value="Verfügbar">Verfügbar</option>
                        </select>
                    </div>
                </div>

                <div className="product-list-frame">
                    {loading && showLoadingMessage ? (
                        <p>Produkte werden geladen...</p> // only while loading
                    ) : !loading && filteredProducts.length > 0 ? (
                        <ProductScrollView products={filteredProducts} />
                    ) : (
                        !loading && <p>Keine Produkte verfügbar</p>
                    )}
                </div>

                {showErrorPopup && (
                    <ErrorPopup
                        message={errorMessage}
                        onClose={handleCloseErrorPopup}
                    />
                )}
            </div>

            <NavbarBottomPhone className="navbar-bottom-phone-instance" />
        </>
    );
}
