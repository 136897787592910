import React, {useEffect, useState} from "react";
import { marked } from "marked";


import {useLocation, useNavigate} from "react-router-dom";
import {fetchProductById} from "../../Utils/DatabaseFetch";
import {useWindowWidth} from "../../breakpoints";
import MushroomDetailMobile from "./MushroomDetailMobile";
import MushroomDetailDesktop from "./MushroomDetailDesktop";

export const MushroomDetail = () => {
  const location = useLocation();
  const { product } = location.state || {}; // get product and state
  const [markdownContent, setMarkdownContent] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(product); //
  const [errorMessage, setErrorMessage] = useState(null);
  const screenWidth = useWindowWidth();
  const navigate = useNavigate();



  const handleAddToCartClick = () => {
       setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  if (!product) {
    return <p>Produktinformationen nicht verfügbar, überprüfe deine Internetverbindung</p>;
  }

  //the refetch purpose is to actualize available weight of the product
  const refetchProduct = async (productId) => {
    try {
      const updatedProduct = await fetchProductById(productId);
      setCurrentProduct(updatedProduct); // state actualize
    } catch (error) {
      setErrorMessage("Fehler beim Aktualisieren des Produkts.");
    }
  };


  useEffect(() => {
    if (product && product.description) {
      // convert Markdown text into html
      setMarkdownContent(marked(product.description));
    }
  }, [product]);

  const handleBackClick = () => {
    navigate(-1);
  };


  return (
      <>
        {screenWidth < 1024 && (
            <MushroomDetailMobile
                product={currentProduct}
                markdownContent={markdownContent}
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                refetchProduct={refetchProduct}
                handleBackClick={handleBackClick}
            />
        )}
        {screenWidth >= 1024 && (
            <MushroomDetailDesktop
                product={currentProduct}
                markdownContent={markdownContent}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                refetchProduct={refetchProduct}
                handleBackClick={handleBackClick}
            />
        )}
      </>
  );
};
