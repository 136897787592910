import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { DescriptionBar } from "../DescriptionBar";
import "./style.css";
import { MushroomImage } from "../Utils/MushroomImage";

export const ProductItem = ({
                              className,
                              mushroomSpecies,
                              description,
                              remainingWeight,
                              productId,
                              price,
                              to,
                            }) => {



  return (
      <Link
          to={`/product/${productId}`}
          state={{ product: { mushroomSpecies, description, remainingWeight, productId, price } }} // product data for state
          className="product-item"
      >
          <MushroomImage mushroomSpecies={mushroomSpecies} size="large" />
        <DescriptionBar
            description={mushroomSpecies}
            remainingWeight={remainingWeight}
        />
      </Link>
  );
};

ProductItem.propTypes = {
  productId: PropTypes.number.isRequired,
  mushroomSpecies: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  remainingWeight: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
};
