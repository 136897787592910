import React from "react";
import "./style.css";
import { ErrorPopup } from "../../components/Utils/ErrorPopup";
import {validateName} from "../../Validation/validateName";
import {validateEmail} from "../../Validation/validateEmail";
import {validatePassword} from "../../Validation/validatePassword";
import {validateConfirmPassword} from "../../Validation/validateConfirmPassword";
import {validatePhoneNumber} from "../../Validation/validatePhoneNumber";
import {Link} from "react-router-dom";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";

export default function RegisterScreenMobile(props) {
    const {
        firstName, setFirstName, firstNameError, setFirstNameError,
        lastName, setLastName, lastNameError, setLastNameError,
        email, setEmail, emailError, setEmailError,
        phoneNumber, setPhone, phoneError, setPhoneError,
        address, setAddress, addressPlaceholder, setAddressPlaceholder,
        password, setPassword, passwordError, setPasswordError,
        confirmPassword, setConfirmPassword, confirmPasswordError, setConfirmPasswordError,
        touchedFields,
        handleBackClick,
        handleRegister,
        handleInputChange,
        errorMessage, clearError,
        showSuccessPopup, successMessage, handleCloseSuccessPopup
    } = props;



    return (
        <>
        <div className="register-screen">
            <header className="register-header">
                <img
                    className="register-screen_back-icon"
                    alt="Back"
                    src="/img/ep-back.png"
                    onClick={handleBackClick}
                />
                <div className="register-screen_header-title">Account erstellen</div>
            </header>

            <div className="register-body">


                <div
                    className={`mobile_input-container ${firstNameError ? "invalid" : touchedFields.firstName && "valid"}`}>
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={firstName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setFirstName, setFirstNameError, validateName, "firstName")
                        }
                    />
                    <label className="mobile_input-label">Vorname</label>
                    <span
                        className={`register-screen_validation-input-icon ${firstNameError ? "" : firstName ? "valid" : ""}`}>
                        {firstNameError ? "❌" : firstName ? "✅" : ""}
                    </span>
                    {touchedFields.firstName && firstNameError &&
                        <p className="register-screen_validation-error-tooltip">{firstNameError}</p>}
                </div>


                <div
                    className={`mobile_input-container ${lastNameError ? "invalid" : touchedFields.lastName && "valid"}`}>
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={lastName}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setLastName, setLastNameError, validateName, "lastName")
                        }
                    />
                    <label className="mobile_input-label">Nachname</label>
                    <span
                        className={`register-screen_validation-input-icon ${lastNameError ? "" : lastName ? "valid" : ""}`}>
                        {lastNameError ? "❌" : lastName ? "✅" : ""}
                    </span>
                    {lastNameError && <p className="register-screen_validation-error-tooltip">{lastNameError}</p>}
                </div>

                <div
                    className={`mobile_input-container ${emailError ? "invalid" : touchedFields.email && "valid"}`}>
                    <input
                        type="email"
                        className="mobile_input-field"
                        placeholder=" "
                        value={email}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setEmail, setEmailError, validateEmail, "email")
                        }
                    />
                    <label className="mobile_input-label">E-Mail</label>
                    <span className={`register-screen_validation-input-icon ${emailError ? "" : email ? "valid" : ""}`}>
                      {emailError ? "❌" : email ? "✅" : ""}
                    </span>
                    {emailError && <p className="register-screen_validation-error-tooltip">{emailError}</p>}
                </div>

                <div
                    className={`mobile_input-container ${phoneError ? "invalid" : touchedFields.phoneNumber && "valid"}`}>
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder=" "
                        value={phoneNumber}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setPhone, setPhoneError, validatePhoneNumber, "phoneNumber")
                        }
                    />
                    <label className="mobile_input-label">Telefonnummer</label>
                    <span
                        className={`register-screen_validation-input-icon ${phoneError ? "" : phoneNumber ? "valid" : ""}`}>
                      {phoneError ? "❌" : phoneNumber ? "✅" : ""}
                    </span>
                    {phoneError && <p className="register-screen_validation-error-tooltip">{phoneError}</p>}
                </div>


                <div className="mobile_input-container">
                    <input
                        type="text"
                        className="mobile_input-field"
                        placeholder={addressPlaceholder || " "}
                        onFocus={() => setAddressPlaceholder("Straße / Hausnummer / PLZ")}
                        onBlur={() => setAddressPlaceholder("")}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="mobile_input-label">Adresse (optional)</label>
                </div>


                <div
                    className={`mobile_input-container ${passwordError ? "invalid" : touchedFields.password && "valid"}`}>
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={password}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setPassword, setPasswordError, validatePassword, "password")
                        }
                    />
                    <label className="mobile_input-label">Passwort</label>
                    <span
                        className={`register-screen_validation-input-icon ${passwordError ? "" : password ? "valid" : ""}`}>
                        {passwordError ? "❌" : password ? "✅" : ""}
                    </span>
                    {passwordError && <p className="register-screen_validation-error-tooltip">{passwordError}</p>}
                </div>


                <div
                    className={`mobile_input-container ${confirmPasswordError ? "invalid" : touchedFields.confirmPassword && "valid"}`}>
                    <input
                        type="password"
                        className="mobile_input-field"
                        placeholder=" "
                        value={confirmPassword}
                        onChange={(e) =>
                            handleInputChange(e.target.value, setConfirmPassword, setConfirmPasswordError, (value) => validateConfirmPassword(password, value), "confirmPassword")
                        }
                    />
                    <label className="mobile_input-label">Passwort wiederholen</label>
                    <span
                        className={`register-screen_validation-input-icon ${confirmPasswordError ? "" : confirmPassword ? "valid" : ""}`}>
                        {confirmPasswordError ? "❌" : confirmPassword ? "✅" : ""}
                     </span>
                    {confirmPasswordError &&
                        <p className="register-screen_validation-error-tooltip">{confirmPasswordError}</p>}
                </div>

                <button className="register-button" onClick={handleRegister}>
                    Erstellen
                </button>
                <Link to="/login-screen" className="desktop-login-link">
                    Bereits registriert? Jetzt anmelden
                </Link>
            </div>

            {errorMessage && (
                <ErrorPopup message={errorMessage} onClose={clearError}/>
            )}



        </div>
            <NavbarBottomPhone/>
        </>
    );
}
