import React from 'react';
import "./ConfirmOrderScreenMobile.css";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import {NavbarBottomPhone} from "../../components/NavbarBottomPhone";

export default function ConfirmOrderScreenMobile(props) {
    const {
        cart,
        note,
        setNote,
        totalSum,
        totalWeight,
        paymentMethod,
        setPaymentMethod,
        handleConfirmOrder,
        showThankYouPopup,
        onCloseThankYouPopup,
        showErrorPopup,
        errorPopupMessage,
        handleCloseErrorPopup,
        navigate,
        timeRemaining,
        isExpired,
    } = props;

    return (
        <>
        <div className="confirm-order-screen-mobile">
            <div  className="confirm-order-content"  >
            <header className="header-container">
                <div className="header-text">Bestellung bestätigen</div>
            </header>

            {showErrorPopup && (
                <ErrorPopup
                    message={errorPopupMessage}
                    onClose={handleCloseErrorPopup}
                />
            )}

                <div className="confirm-order-content">
                    <h3>Deine Produkte</h3>
                    <ul>
                        {cart.items.map((item) => (
                            <li key={item.productId}>
                                {item.mushroomSpecies}: {item.quantity}g
                                | {(item.price / 1000 * item.quantity).toFixed(2)} €
                            </li>
                        ))}
                    </ul>

                    <p className="aggregated-timer-mobile">
                        Reservierung läuft ab in: <strong>{timeRemaining}</strong>
                    </p>

                    <h3>Anmerkung</h3>
                    <textarea
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />

                    <h3>Zahlungsart</h3>
                    <div className="payment-methods">
                        <label>
                            <input
                                type="radio"
                                value="CASH"
                                checked={paymentMethod === 'CASH'}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                            />
                            Barzahlung
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="BANK_TRANSFER"
                                checked={paymentMethod === 'BANK_TRANSFER'}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                            />
                            Überweisung
                        </label>
                        {paymentMethod === 'BANK_TRANSFER' && (
                            <div className="iban-info">
                                <p>IBAN: AT64 3704 0044 0532 0130 00</p>
                            </div>
                        )}
                    </div>

                    <div className="summary">
                        <p><strong>Gesamtgewicht:</strong> {totalWeight} g</p>
                        <p><strong>Gesamtsumme:</strong> {totalSum} €</p>
                    </div>

                    <div className="confirm-order-screen-mobile_action-buttons">
                        <button
                            className="back-button"
                            onClick={() => navigate('/shoppingcart-screen')}
                        >
                            Zurück
                        </button>
                        <button
                            className="confirm-order-screen-mobile_confirm-button"
                            onClick={handleConfirmOrder}
                        >
                            Bestellung bestätigen
                        </button>
                    </div>
                </div>

                {showThankYouPopup && (
                    <div className="thank-you-popup-overlay">
                    <div className="thank-you-popup">
                        <p>Danke für deine Bestellung!</p>
                        <button
                            className="continue-shopping-button"
                            onClick={onCloseThankYouPopup}
                        >
                            Weiter shoppen
                        </button>
                    </div>
                </div>
            )}
            </div>
            <NavbarBottomPhone />
        </div>

        </>
    );
}
