import React, { useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import "./AccountScreenMobile.css";
import {useAuth} from "../../context/AuthContext";
import {fetchLastOrderProducts, cancelOrder} from "../../Utils/DatabaseFetch";
import {useFetchWithErrorHandling} from "../../Utils/genericFetchWithErrorHandling";
import {ErrorPopup} from "../../components/Utils/ErrorPopup";
import AccountScreenMobile from "./AccountScreenMobile";
import AccountScreenDesktop from "./AccountScreenDesktop";
import {useWindowWidth} from "../../breakpoints";
import {ConfirmPopup} from "../../components/Utils/ConfirmPopup";
import {SuccessPopup} from "../../components/Utils/SuccessPopup";
import {ErrorPopupConfigurableButtons} from "../../components/Utils/ErrorPopupConfigurableButtons";


export const AccountScreen = () => {
  const screenWidth = useWindowWidth();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);






    if (!user) {
        return (
            <div className="account-screen">
                <ErrorPopupConfigurableButtons
                    message="Bitte melde dich zuerst an, um auf diese Seite zuzugreifen."
                    onClose={() => {
                        setShowErrorPopup(false);
                        navigate(-1);
                    }
                }
                    buttons={[
                        {
                            label: "Zur Anmeldung",
                            action: () => {
                                setShowErrorPopup(false);
                                navigate("/login-screen");
                            },
                        },
                        {
                            label: "Abbrechen",
                            action: () => {
                            setShowErrorPopup(false);
                            navigate(-1);
                    },

                        },
                    ]}
                />
            </div>
        );
    }
    const { data: lastOrderProducts, loading, errorMessage, setErrorMessage } = useFetchWithErrorHandling(
        () => fetchLastOrderProducts(user?.customerId),
        [user?.customerId, refreshTrigger] // to be sure customer exist
    );

    const refreshLastOrders = () => {
        setRefreshTrigger((prev) => !prev); // switch trigger
    };


    useEffect(() => {
        if (errorMessage && !showErrorPopup) {
           setShowErrorPopup(true);
        }
    }, [errorMessage, showErrorPopup]);

    if (loading) {
        return <div className="account-screen">Lädt...</div>;
    }



    const handleLogout = () => {
    logout();
    navigate("/start-screen");
  };

    const safeLastOrderProducts = lastOrderProducts || [];


    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
        setErrorMessage(null);  //delete backend error

    };

    const handleSuccessPopup = () => {
        setShowSuccessPopup(false);
        setSuccessMessage(null);

    };

    const handlePopupConfirm = async () => {
        setShowConfirmPopup(false);
        if (!cancelOrderId) return;

        try {
            const response = await cancelOrder(cancelOrderId);
            setSuccessMessage(response);
            setShowSuccessPopup(true);

            refreshLastOrders();
        } catch (error) {
            setErrorMessage(error.message);
            setShowErrorPopup(true);
        }
    };

    const handlePopupCancel = () => {
        setShowConfirmPopup(false);
        setCancelOrderId(null);
    };

    const initiateCancelOrder = (orderId) => {
        setCancelOrderId(orderId);
        setShowConfirmPopup(true);
    };

    const handleShowHelp = () => {
        navigate("/notifications-help");
    };




    return (
        <>
            {screenWidth < 1024 ? (
                <AccountScreenMobile
                    user={user}
                    lastOrderProducts={lastOrderProducts}
                    safeLastOrderProducts={safeLastOrderProducts}
                    showErrorPopup={showErrorPopup}
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                    initiateCancelOrder={initiateCancelOrder}
                    handleSuccessPopup={handleSuccessPopup}
                    handlePopupConfirm={handlePopupConfirm}
                    handlePopupCancel={handlePopupCancel}
                    showConfirmPopup={showConfirmPopup}
                    showSuccessPopup={showSuccessPopup}
                    handleLogout={handleLogout}
                    handleShowHelp={handleShowHelp}

                />
            ) : (
                <AccountScreenDesktop
                    user={user}
                    lastOrderProducts={lastOrderProducts}
                    safeLastOrderProducts={safeLastOrderProducts}
                    showErrorPopup={showErrorPopup}
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                    handleCloseErrorPopup={handleCloseErrorPopup}
                    initiateCancelOrder={initiateCancelOrder}
                    handleSuccessPopup={handleSuccessPopup}
                    handlePopupConfirm={handlePopupConfirm}
                    handlePopupCancel={handlePopupCancel}
                    showConfirmPopup={showConfirmPopup}
                    showSuccessPopup={showSuccessPopup}
                    handleLogout={handleLogout}
                    handleShowHelp={handleShowHelp}
                />
            )}
            {showConfirmPopup && (
                <ConfirmPopup
                    title="Bestellung stornieren"
                    message="Möchtest du diese Bestellung wirklich stornieren?"
                    onConfirm={handlePopupConfirm}
                    onCancel={handlePopupCancel}
                />
            )}
            {showSuccessPopup && (
                <SuccessPopup
                    title="Erfolg"
                    message={successMessage}
                    onClose={handleSuccessPopup}
                />
            )}
        </>
    );
};
