import React, {useEffect, useRef, useState} from "react";
import { useNotificationSettings } from "./NotificationSettingsLogic";
import "./NotificationSettingsDesktop.css";
import {NavigationbarTop} from "../../components/NavigationbarTop";
import {SettingDetailItem} from "../../components/SettingDetailItem";
import {Footer} from "../../components/Footer/Footer";
import {useLocation} from "react-router-dom";

export const NotificationSettingsDesktop = () => {
    const {
        isMushroomIntervalExpanded,
        setIsMushroomIntervalExpanded,
        handleIntervalToggle,
        handleMushroomToggle,
        handleBackClick,
        handleFavoriteToggle,
        favoriteSelections,
        productList,
        reservationSelections,
        handleReservationToggle
    } = useNotificationSettings();
    const favoritesRef = useRef(null);
    const location = useLocation();


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("scrollTo") === "favorites" && favoritesRef.current) {
            // wait some time so component can fully load, then scroll smooth
            setTimeout(() => {
                favoritesRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 100);
        }
    }, [location]);


    return (
        <>
            <NavigationbarTop />
            <div className="notification-settings-desktop-layout">
                <aside className="notification-settings-desktop_aside">
                    <h1 className="notification-settings-desktop_aside-title">Mitteilungsoptionen</h1>
                    <p className="notification-settings-desktop_aside-subtitle">Passe deine Einstellungen an</p>
                    <button className="login-desktop_aside-back-button" onClick={handleBackClick}>
                        <img src="/img/ep_back_white.png" alt="Zurück" className="login-desktop_back-link-icon"/>
                        <span className="login-desktop_back-link-text">Zurück</span>
                    </button>
                </aside>

                <div className="notification-settings-desktop_main">
                    <h2 className="notification-settings-desktop_section-title">Bestellungen</h2>
                    <SettingDetailItem
                        className="setting-option"
                        title="Bestellung abholbereit"
                        description="Benachrichtige bei abholbereiten Bestellungen."
                        notificationKey="orderReady"
                        defaultState="on"
                        onToggle={handleMushroomToggle}
                    />
                    <SettingDetailItem
                        className="setting-option"
                        title="Vormerkungen"
                        description="Informiere über geerntete Vormerkungen."
                        notificationKey="reservation"
                        defaultState="on"
                        hasSubList={true}
                        isReservationSubList={true}
                        onToggle={handleMushroomToggle}
                    />

                    <h2 className="notification-settings-desktop_section-title">Pilze</h2>
                    <div
                        className="notification-settings-desktop-expandable-container"
                        onClick={() => setIsMushroomIntervalExpanded(!isMushroomIntervalExpanded)}
                        role="button"
                    >
                        <div className="expandable-header">
                            <div className="expandable-text">
                                <h3 className="expandable-title">Mitteilungsintervall</h3>
                                <p className="expandable-description">
                                    Wähle, wie oft du Benachrichtigungen erhalten möchtest.
                                </p>
                            </div>
                            <img
                                className={`expandable-icon ${isMushroomIntervalExpanded ? "expanded" : ""}`}
                                src="/img/ic-baseline-greater-than-16.png"
                                alt="Toggle Expand"
                            />
                        </div>
                    </div>
                    {isMushroomIntervalExpanded && (
                        <div className="expandable-sublist">
                            <SettingDetailItem
                                className="setting-option"
                                title="Pilzflüsterer"
                                description="max. einmal pro Sorte/24h"
                                notificationKey="mushroomWhisperer"
                                defaultState="on"
                                onToggle={handleIntervalToggle}
                            />
                            <SettingDetailItem
                                className="setting-option"
                                title="Schwammalheißhunger"
                                description="Jede Ernte informiert"
                                notificationKey="mushroomCravings"
                                onToggle={handleIntervalToggle}
                            />
                        </div>
                    )}

                    <SettingDetailItem
                        className="setting-option"
                        title="Alle Pilze"
                        description="Alle Sorten benachrichtigen."
                        notificationKey="allMushrooms"
                        onToggle={handleMushroomToggle}
                    />
                        <div ref={favoritesRef}>
                            <SettingDetailItem
                                className="setting-option"
                                title="Favoriten"
                                description="Nur deine Favoriten informieren dich."
                                notificationKey="favoriteMushrooms"
                                onToggle={handleMushroomToggle}
                                hasSubList={true}
                            />
                        </div>
                    </div>
                </div>
                <Footer/>
        </>
    );
};
