
import React, { createContext, useContext, useReducer, useEffect } from 'react';

// initial
const initialState = {
    items: [], //zero products
};

// Action-Type
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const CLEAR_CART = 'CLEAR_CART';
const MARK_ITEM_AS_EXPIRED = 'MARK_ITEM_AS_EXPIRED';
const UPDATE_ITEM_EXPIRY = 'UPDATE_ITEM_EXPIRY';

// reducer function for shopping cart
function cartReducer(state, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const existingItemIndex = state.items.findIndex(
                (cartItem) => cartItem.productId === action.payload.productId
            );
            // if product is already in shopping cart add up
            if (existingItemIndex >= 0) {

                const updatedItems = [...state.items];
                //initialize harvestIds as array
                updatedItems[existingItemIndex].harvestIds = updatedItems[existingItemIndex].harvestIds || [];

                //update quantity
                updatedItems[existingItemIndex].quantity = Number(updatedItems[existingItemIndex].quantity) + Number(action.payload.quantity);

                //add harvest ids to set
                const newHarvestIds = Array.isArray(action.payload.harvestIds) ? action.payload.harvestIds : [];
                updatedItems[existingItemIndex].harvestIds = [
                    ...new Set([...updatedItems[existingItemIndex].harvestIds, ...newHarvestIds])
                ];

                return {
                    ...state,
                    items: updatedItems,
                };
            } else {

                return {
                    ...state,
                    items: [...state.items, action.payload],
                };
            }
        case REMOVE_FROM_CART:
            return { ...state, items: state.items.filter(item => item.productId !== action.payload) };
        case MARK_ITEM_AS_EXPIRED:
            return {
                ...state,
                items: state.items.map(item =>
                    item.productId === action.payload ? { ...item, isExpired: true } : item
                ),
            };
        case UPDATE_ITEM_EXPIRY:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.productId === action.payload.productId) {
                        return {
                            ...item,
                            expiryDates: action.payload.newExpiryDates,
                            isExpired: false, // reset timer
                        };
                    }
                    return item;
                }),
            };
        case CLEAR_CART:
            return { ...state, items: [] };
        default:
            return state;
    }
}



// create context
const CartContext = createContext(undefined);

// cart provider component
export function CartProvider({ children }) {
    const [state, dispatch] = useReducer(cartReducer, initialState, () => {
        // get cart from local storage if there is one
        const localData = localStorage.getItem('cart');
        return localData ? JSON.parse(localData) : initialState;
    });

    // with every change store cart in local storage
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(state));
    }, [state]);

    const addToCart = (item) => dispatch({ type: ADD_TO_CART, payload: item });
    const removeFromCart = (productId) => dispatch({ type: REMOVE_FROM_CART, payload: productId });
    const markItemAsExpired = (productId) => {
        dispatch({ type: MARK_ITEM_AS_EXPIRED, payload: productId });
    };
    const updateCartItemExpiry = (productId, newExpiryDates) => {
        dispatch({
            type: UPDATE_ITEM_EXPIRY,
            payload: {
                productId,
                newExpiryDates,
            },
        });
    };
    const clearCart = () => dispatch({ type: CLEAR_CART });

    return (
        <CartContext.Provider value={{ cart: state, addToCart, removeFromCart, clearCart, markItemAsExpired, updateCartItemExpiry }}>
            {children}
        </CartContext.Provider>
    );
}

// custom hook
export function useCart() {
    return useContext(CartContext);
}
